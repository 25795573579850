import React, { useState } from 'react'

const Accordian = () => {
    const [activeQuestion, setActiveQuestion] = useState("q1");
    const toggleQuestion = (question) => {
        setActiveQuestion(activeQuestion === question ? null : question);
    };
    return (
        <>
            <div className='w-[60%] mobile:w-full flex flex-col gap-3'>
                <p className='text-h2 font-[700] text-secondary mobile:hidden font-lora'>
                    Choose a plan   <span className='text-[#FFF]'> That<br />that Works For You</span>
                </p>
                <p className='text-h3 font-bold text-secondary font-lora laptop:hidden'>
                    Choose A Plan  <span className='text-[#FFF]'> That Works For You</span>
                </p>
                <hr className='w-[15%] mobile:w-[30%] mt-5 border-[3px] border-[#FFBB6B] mb-5 mobile:my-2' />
                <div>
                    <p className='text-[20px] font-[600] text-[#FFF] font-lora cursor-pointer' onClick={() => toggleQuestion('q1')}>
                        Q. What support and service package are available?
                    </p>
                    {activeQuestion === 'q1' && (
                        <>
                            <p className='text-[16px] font-400 font-poppins text-[#FFF] w-[75%] mobile:w-full'>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pharetra eu gravida et justo, ac morbi vitae vitae, purus. Donec sit massa odio elit volutpat ut et.
                            </p>
                            <div className="w-[75%] bg-[#FDF3DA] mt-2 rounded-full h-1.5 dark:bg-gray-700">
                                <div className="bg-secondary h-1.5 rounded-full w-[35%]" ></div>
                            </div>
                        </>
                    )}
                </div>

                <div className='flex flex-col gap-10 mobile:gap-5 mt-5'>
                    <div>
                        <p className='text-[20px] font-[600] text-[#FFF] font-lora cursor-pointer' onClick={() => toggleQuestion('q2')}>
                            Q. How can I make the payment?
                        </p>
                        {activeQuestion === 'q2' && (
                            <>
                                <p className='text-[16px] font-400 font-poppins text-[#FFF] w-[75%] mobile:w-full'>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pharetra eu gravida et justo, ac morbi vitae vitae, purus. Donec sit massa odio elit volutpat ut et.
                                </p>
                                <div className="w-[75%] bg-[#FDF3DA] mt-2 rounded-full h-1.5 dark:bg-gray-700">
                                    <div className="bg-secondary h-1.5 rounded-full w-[35%]" ></div>
                                </div>
                            </>
                        )}
                    </div>
                    <div>
                        <p className='text-[20px] font-[600] text-[#FFF] font-lora cursor-pointer' onClick={() => toggleQuestion('q3')}>
                            Q. How long will I have access to the service?
                        </p>
                        {activeQuestion === 'q3' && (
                            <>
                                <p className='text-[16px] font-400 font-poppins text-[#FFF] w-[75%] mobile:w-full'>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pharetra eu gravida et justo, ac morbi vitae vitae, purus. Donec sit massa odio elit volutpat ut et.
                                </p>
                                <div className="w-[75%] bg-[#FDF3DA] mt-2 rounded-full h-1.5 dark:bg-gray-700">
                                    <div className="bg-secondary h-1.5 rounded-full w-[35%]" ></div>
                                </div>
                            </>
                        )}
                    </div>
                    <div>
                        <p className='text-[20px] font-[600] text-[#FFF] font-lora cursor-pointer' onClick={() => toggleQuestion('q4')}>
                            Q. Do you provide any refund?
                        </p>
                        {activeQuestion === 'q4' && (
                            <>
                                <p className='text-[16px] font-400 font-poppins text-[#FFF] w-[75%] mobile:w-full'>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pharetra eu gravida et justo, ac morbi vitae vitae, purus. Donec sit massa odio elit volutpat ut et.
                                </p>
                                <div className="w-[75%] bg-[#FDF3DA] mt-2 rounded-full h-1.5 dark:bg-gray-700">
                                    <div className="bg-secondary h-1.5 rounded-full w-[35%]" ></div>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </>
    )
}

export default Accordian