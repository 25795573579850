import React, { useEffect, useState } from 'react'
import ImagesRes from '../components/Resources/ImagesRes';
import VideoRes from '../components/Resources/VideoRes';
import PodCast from '../components/Resources/PodCast';
import Testimonials from "../components/HomePage/Testimonials"
import fb from "../Images/facebook.png"
import ig from "../Images/ig.png"
import telegram from "../Images/telegram.png"
import { useDispatch, useSelector } from 'react-redux';
import { allgallery } from '../store/actions/website.action';
const Resources = () => {
  const { galaryData, loader } = useSelector((state) => state?.web);
  console.log("galaryData=====>>", galaryData);
  const [openTab, setOpenTab] = useState(1);
  const dispatch = useDispatch();
  useEffect(() => {
    if (openTab === 1) {
      const payload = {
        "title": "",
        "type": "video", //image,video
        // "createdDateFrom": "19-01-2024",
        // "createdDateTo": "21-01-2024",
        "pageNo": 0,
        "perPageResults": 0
      }
      dispatch(allgallery(payload))
    }
    if (openTab === 2) {
      const payload = {
        "title": "",
        "type": "image", //image,video
        // "createdDateFrom": "19-01-2024",
        // "createdDateTo": "21-01-2024",
        "pageNo": 0,
        "perPageResults": 0
      }
      dispatch(allgallery(payload))
    }
    if (openTab === 3) {
      const payload = {
        "title": "",
        "type": "video", //image,video
        // "createdDateFrom": "19-01-2024",
        // "createdDateTo": "21-01-2024",
        "pageNo": 0,
        "perPageResults": 0
      }
      dispatch(allgallery(payload))
    }
  }, [dispatch, openTab])
  return (
    <div className="bg-[url('https://tfspublic.s3.ap-south-1.amazonaws.com/images/Resources/background.png')] bg-contain bg-no-repeat h-full -mt-5 mb-32 ">
      <div className='flex flex-col justify-center items-center '>
        <p className='text-secondary text-h5 font-bold	mt-32'>Resources</p>
        <p className='text-primary font-bold mt-5 text-center text-h2 mobile:text-h4 font-lora'>Empower Your Learning: Essential <br />
          Resources at Your Fingertips</p>
        <div className="flex flex-wrap w-full mt-20 ">
          <div className="w-full">
            <ul
              className="flex justify-center gap-[30px] mb-0 mobile:ml-10 mobile:gap-10  list-none mobile:overflow-auto pt-3 pb-4 flex-row"
              role="tablist"
            >
              <li className="-mb-px mr-2 last:mr-0   text-center">
                <a className={"font-poppins mobile:text-[18px] text-h6 font-medium px-10 text-primary py-2 border-[1px] border-primary rounded-[37px] " + (openTab === 1 ? "bg-secondary border-secondary boxShadow " : " ")}
                  onClick={e => {
                    e.preventDefault();
                    setOpenTab(1);
                  }}
                  data-toggle="tab"
                  href="#videos"
                  role="tablist"
                >
                  Videos
                </a>
              </li>
              <li className="-mb-px mr-2 last:mr-0  text-center">
                <a className={"font-poppins mobile:text-[18px] text-h6 font-medium px-10 p text-primary py-2 border-[1px] border-primary rounded-[37px] " + (openTab === 2 ? "bg-secondary border-secondary boxShadow" : "")}
                  onClick={e => {
                    e.preventDefault();
                    setOpenTab(2);
                  }}
                  data-toggle="tab"
                  href="#images"
                  role="tablist"
                >
                  Images
                </a>
              </li>
              <li className="-mb-px mr-2 last:mr-0  text-center">
                <a
                  className={"font-poppins mobile:text-[18px] text-h6 font-medium px-10 py-2 text-primary border-[1px] border-primary rounded-[37px] " + (openTab === 3 ? "bg-secondary border-secondary boxShadow" : "")}
                  onClick={e => {
                    e.preventDefault();
                    setOpenTab(3);
                  }}
                  data-toggle="tab"
                  href="#podcast"
                  role="tablist"
                >
                  Podcast
                </a>
              </li>
            </ul>
            <div className="relative flex flex-col min-w-0 mt-20 mobile:mt-10 w-full mb-6">
              <div className=" flex-auto flex justify-center">
                <div className="tab-content tab-space w-[80%] mobile:w-[90%] flex justify-center flex-col">
                  <div className={openTab === 1 ? "block" : "hidden"} id="videos">
                    <VideoRes galaryData={galaryData} loader={loader} />
                  </div>
                  <div className={openTab === 2 ? "block" : "hidden"} id="images">
                    <ImagesRes galaryData={galaryData} loader={loader} />
                  </div>
                  <div className={openTab === 3 ? "block" : "hidden"} id="podcast">
                    <PodCast galaryData={galaryData} loader={loader} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='bg-secondary w-full mt-20'>
          <div className='max-w-screen-xl mt-10 flex flex-wrap items-center justify-between mx-auto pt-6 pl-6 pr-6 '>
            <p className='text-h2 text-primary font-bold'>Let’s Get Social</p>
            <p className='text-body text-primary font-normal font-poppins mt-5'>Follow us on Facebook and Instagram to stay in touch, and get the latest updates, news and messages as we <br />
              continue to share the best business practices and strategies that work for us, in hopes that it can help you succeed as well.</p>
            <div className='grid grid-cols-3 gap-14  mobile:grid-cols-1 mt-20 w-full '>
              <div className='relative rounded-t-[60px] border-[1px] glassbg2 border-white px-4 pt-4'>
                <img src={fb} alt='' className=" w-full h-auto rounded-t-[43px]" />
                {/* Overlay */}
                <div className="absolute w-[100%]  inset-x-0 bottom-0 h-[22%] bg-[#214284] opacity-[60%]"></div>

                {/* Text */}
                <div className='absolute w-full flex justify-center items-center  bottom-0'>
                  <div className='text-[20px] text-primary bg-tertiary rounded-[35px] mb-5 -ml-5 w-[50%] py-3 px-9 boxShadow font-[600] font-poppins '> Facebook </div>
                </div>
              </div>

              <div className=' relative rounded-t-[60px] border-[1px] glassbg2 border-white px-4 pt-4'>
                <img src={ig} alt='' className=" w-full h-auto rounded-t-[43px]" />
                {/* Overlay */}
                <div className="absolute w-[100%]  inset-x-0 bottom-0 h-[22%] bg-[#214284] opacity-[60%]"></div>

                {/* Text */}
                <div className='absolute w-full flex justify-center items-center  bottom-0'>
                  <div className='text-[20px] text-primary bg-tertiary rounded-[35px] mb-5 -ml-5 w-[50%] py-3 px-9 boxShadow font-[600] font-poppins '> Instagram </div>
                </div>
              </div>
              <div className='relative rounded-t-[60px] border-[1px] glassbg2 border-white px-4 pt-4'>
                <img src={telegram} alt='' className="w-full h-auto rounded-t-[43px]" />
                {/* Overlay */}
                <div className="absolute w-[100%]  inset-x-0 bottom-0 h-[22%] bg-[#214284] opacity-[60%]"></div>

                {/* Text */}
                <div className='absolute w-full flex justify-center items-center  bottom-0'>
                  <div className='text-[20px] text-primary bg-tertiary rounded-[35px] mb-5 -ml-5 w-[50%] py-3 px-9 boxShadow font-[600] font-poppins '> Telegram </div>
                </div>
              </div>

            </div>
          </div>

        </div>
        <Testimonials />
      </div>
    </div>
  )
}

export default Resources
