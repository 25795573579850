import React from 'react'

const ForceLogin = ({ setPopuptype, handleforcelogin }) => {
   
    return (
        <>
            <div
                className="overflow-y-auto overflow-x-hidden fixed z-[999] bg-black bg-opacity-[0.6] justify-center h-full flex items-center w-full inset-0 max-h-full font-raleway"
                onClick={(e) => {
                    e.currentTarget === e.target && setPopuptype();
                }}
            >
                <div className="justify-center items-center gap-5 flex  flex-col p-5 w-[30%] tablet:w-[90%] mobile:w-[90%] text-center max-h-full bg-white opacity-100 inset-5">
                    <h4>Session already exist in another device! wants to login here? </h4>
                    <div className='flex gap-5'>
                        <p className='bg-primary text-white py-2 px-6 cursor-pointer' onClick={handleforcelogin}>Yes</p>
                        <p className='border-primary border text-primary py-2 px-6 cursor-pointer' onClick={()=>{setPopuptype()}}>No</p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ForceLogin
