import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Link, redirect, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { enterBasicDetails } from "../store/actions/admin.action";
import toast from "react-hot-toast";

const SignupSchema = Yup.object().shape({
  firstName: Yup.string().required("First Name is required"),
  lastName: Yup.string().required("Last Name is required"),
  email: Yup.string().email("Invalid email").required("Email is required"),
  mobileNo: Yup.string()
    .matches(/^[0-9]+$/, "Must be only digits")
    .required("Mobile No is required"),
});

const GetStarted = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  return (
    <div className="w-full h-screen bg-[#FFFAED] overflow-hidden relative">

      <section className="text-center py-5 ">
        <div className="container mx-auto">
          <h1 className="text-4xl font-semibold text-[#214284] font-lora">
            Welcome To TFS
          </h1>
        </div>
      </section>
      <div className="flex justify-center text-center w-full rounded-[40px] mt-7">
        <div className=" shadow-md bg-white w-[1200px] h-full rounded-[40px] mt-7 ">
          <p
            className="text-center font-lora mb-4 mt-4 font-bold text-2xl"
            style={{ color: "rgba(255, 187, 107, 1)" }}
          >
            Tell Us About Yourself
          </p>
          <hr className="border-t-2 opacity-16 " />
          <div className="h-full w-full flex justify-center items-center mb-10 mt-10">
            <Formik
              initialValues={{
                firstName: "",
                lastName: "",
                email: "",
                mobileNo: "",
              }}
              validationSchema={SignupSchema}
              onSubmit={(values) => {
                const payload = {
                  "emailId": values?.email,
                  "mobileNo": values?.mobileNo,
                  "firstName": values?.firstName,
                  "lastName": values?.lastName,
                  "grantType": "userOnboardingStep1"
                };

                // Store form values in localStorage
                localStorage.setItem("formData", JSON.stringify(values));

                dispatch(enterBasicDetails(payload, (response) => {
                  if (response?.status === 200) {
                    // Store response data in localStorage
                    localStorage.setItem("responseData", JSON.stringify(response?.data));
                    localStorage.setItem("newdata", values?.email);
                    localStorage.setItem("basicdet", JSON.stringify(values));
                    toast?.success(response?.data?.message);
                    navigate("/confirmation");
                  }
                }))
              }}
            >
              {({ errors, touched, isValid, dirty }) => (
                <Form className="w-full font-poppins text-primary">
                  <div className="space-y-4 px-52">
                    <div className="flex flex-col md:flex-row md:space-x-4 md:gap-11 w-full">
                      <div className="w-full ">
                        <label className="text-start block">
                          First Name{" "}
                          <span className="text-yellow-500" style={{ color: "#FFBB6B" }}>
                            *
                          </span>
                        </label>
                        <Field
                          name="firstName"
                          placeholder="Enter your first name"
                          className="mt-1 focus:ring-0 focus:outline-none block w-[330px] border h-[45px] border-[#214284] rounded-[39px] border-1 shadow-sm py-2 px-3"
                        />
                        <ErrorMessage
                          name="firstName"
                          component="div"
                          className="text-start text-red-600 text-sm"
                        />
                      </div>
                      <div className="w-full ">
                        <label className="text-start block">
                          Last Name
                          <span className="text-yellow-500" style={{ color: "#FFBB6B" }}>
                            *
                          </span>
                        </label>
                        <Field
                          name="lastName"
                          placeholder="Enter your last name"
                          className="mt-1 block focus:ring-0 focus:outline-none w-[330px]  border h-[45px] border-[#214284] rounded-[39px] border-1 shadow-sm py-2 px-3"
                        />
                        <ErrorMessage
                          name="lastName"
                          component="div"
                          className="mt-1 block w-[330px] h-[45px] border border-[#214284] rounded-[39px] border-1 shadow-sm py-2 px-3"
                        />
                      </div>
                    </div>
                    <div className="flex flex-col md:flex-row md:space-x-4 md:gap-11 w-full">
                      <div className="w-full md:w-1/2">
                        <label className="text-start block">
                          Email
                          <span className="text-yellow-500" style={{ color: "#FFBB6B" }}>
                            *
                          </span>
                        </label>
                        <Field
                          name="email"
                          type="email"
                          placeholder="Enter your email"
                          className="mt-1 block w-[330px] focus:ring-0 focus:outline-none h-[45px] border border-[#214284] rounded-[39px] border-1 shadow-sm py-2 px-3"
                        />
                        <ErrorMessage
                          name="email"
                          component="div"
                          className="text-red-600 text-sm"
                        />
                      </div>
                      <div className="w-full md:w-1/2">
                        <label className="text-start block">
                          Mobile No
                          <span className="text-yellow-500" style={{ color: "#FFBB6B" }}>
                            *
                          </span>
                        </label>
                        <Field
                          name="mobileNo"
                          placeholder="Enter your mobile number"
                          className="mt-1 block w-[330px] focus:ring-0 focus:outline-none h-[45px] border border-[#214284] rounded-[39px] border-1 shadow-sm py-2 px-3"
                        />
                        <ErrorMessage
                          name="mobileNo"
                          component="div"
                          className="text-red-600 text-sm"
                        />
                      </div>
                    </div>
                  </div>
                  <hr className="border-t-2 opacity-16 mb-7 mt-7" />
                  <button
                    type="submit"
                    className={`text-center w-[378px] h-[50px]   text-white rounded-[36px] shadow-sm ${isValid && dirty ? " border border-[#214284] bg-[#214284]" : "bg-gray-300"}`}
                    style={{
                      fontFamily: "Lora",
                      fontSize: "16px",
                      fontWeight: 700,
                      lineHeight: "20px",
                    }}
                    disabled={!isValid || !dirty}
                  >
                    SAVE
                  </button>
                </Form>

              )}
            </Formik>
          </div>
        </div>
      </div>


    </div>
  );
};

export default GetStarted;
