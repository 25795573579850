import React, { useEffect, useState } from 'react'
import HeroSection from '../components/HomePage/HeroSection'
import About from '../components/HomePage/About'
import GradientDiv from '../components/HomePage/GradientDiv'
import SubscribeImg from "../Images/subscribe.svg"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Carousel from '../components/HomePage/Carousel'
import Testimonials from '../components/HomePage/Testimonials'
import Blogs from '../components/HomePage/Blogs'
import PricingPlan from '../components/PricingPlan'
import FormPopup from '../components/FormPopup'
import step1 from "../Images/step1.png"
import step2 from "../Images/step2.png"
import step3 from "../Images/step3.png"
import step4 from "../Images/step4.png"
import chhoseus from "../Images/chooseus.png"
import { Link } from 'react-router-dom'
import DownloadBrchure from '../components/Download'
const Homepage = () => {
    const [initialPopup, setInitialPopup] = useState(true);
    const [modal, setmodal] = useState(false);
    const downloadDemoFile = () => {
        // Create a Blob with dummy content
        const blob = new Blob(['This is a demo file content.'], { type: 'text/plain' });
        const url = URL.createObjectURL(blob);

        // Create a temporary <a> element to trigger the download
        const a = document.createElement('a');
        a.href = url;
        a.download = 'demo.txt'; // File name
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);

        // Release the Blob URL
        URL.revokeObjectURL(url);
    };
    const handlePopupClose = () => {
        setInitialPopup(false);
        localStorage.setItem('popupShown', 'true');
    };
    useEffect(() => {
        const popupShown = localStorage.getItem('popupShown');
        if (!popupShown) {
            setInitialPopup(true);
        }
    }, []);
    return (
        <>
            {modal && <DownloadBrchure setmodal={setmodal} />}
            {initialPopup && <FormPopup setInitialPopup={handlePopupClose} />}
            <div className="bg-[url('./Images/bg.svg')] h-full  -mt-5 mobile:overflow-hidden">
                <HeroSection />
                <About />
                <div className='w-full bg-section-bg  bg-cover mobile:h-full mobile:bg-secondary bg-center bg-no-repeat min-h-[342px] z-20 relative'>
                    <div className='max-w-screen-xl py-8 mobile:px-5 px-10 flex h-full flex-wrap items-center justify-between mx-auto'>
                        <div className='w-full flex mobile:flex-col items-center  '>
                            <div className='w-[20%] mobile:hidden'>
                                <img src='https://tfspublic.s3.ap-south-1.amazonaws.com/images/Home+Page/bull.png' alt='' className='w-[80%]' />
                            </div>
                            <div>
                                <p className='text-[36px] font-lora font-[700] text-primary'>
                                    We will help <br />
                                    you to educate <br />
                                    regarding trading <br />
                                </p>
                            </div>
                            <div className='flex mobile:flex-col mobile:w-full  mobile:gap-5 mobile:p-4 gap-10 pl-14'>
                                <div className='bg-white mobile:w-full w-[170px] rounded-[15px] h-[150px] flex flex-col text-center justify-center items-center'>
                                    <p className='text-[36px] font-lora font-[700]  text-primary'>22,000k</p>
                                    <p className='text-[16px] font-[400] font-poppins text-primary'>Growing<br />
                                        Community</p>
                                </div>
                                <div className='bg-white mobile:w-full  w-[170px] rounded-[15px] h-[150px] flex flex-col text-center justify-center items-center'>
                                    <p className='text-[36px] font-lora font-[700]  text-primary'>200k</p>
                                    <p className='text-[16px] font-[400] font-poppins text-primary'>Feedback<br />
                                        Received</p>
                                </div>
                                <div className='bg-white mobile:w-full  w-[170px] rounded-[15px] h-[150px] flex flex-col text-center justify-center items-center'>
                                    <p className='text-[36px] font-lora font-[700]  text-primary'>1800+</p>
                                    <p className='text-[16px] font-[400] font-poppins text-primary'>TIC <br />
                                        Students</p>
                                </div>
                            </div>
                            <div className='w-full laptop:hidden -mb-44'>
                                <img src={SubscribeImg} alt='' className='w-[80%]' />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='w-full bg-primary mt-56 laptop:hidden '>
                    <div>
                        <p className='text-white font-lora text-center py-3 text-[36px]'>Upcoming Batch</p>
                    </div>
                </div>
                <div className='bg-primary h-[60px] w-[2035px]  overflow-hidden -ml-10 rotate4 flex gap-16 items-center mobile:hidden'>
                    <div className='flex gap-16 items-center whitespace-nowrap animate-scroll'>
                        <div>
                            <p className='text-white text-[36px]'>Upcoming Batch</p>
                        </div>
                        <div>
                            <p className='text-white text-[36px]'>Upcoming Batch</p>
                        </div>
                        <div>
                            <p className='text-white text-[36px]'>Upcoming Batch</p>
                        </div>
                        <div>
                            <p className='text-white text-[36px]'>Upcoming Batch</p>
                        </div>
                        <div>
                            <p className='text-white text-[36px]'>Upcoming Batch</p>
                        </div>
                        <div>
                            <p className='text-white text-[36px]'>Upcoming Batch</p>
                        </div>
                        <div>
                            <p className='text-white text-[36px]'>Upcoming Batch</p>
                        </div>
                        <div>
                            <p className='text-white text-[36px]'>Upcoming Batch</p>
                        </div>
                    </div>
                </div>

                <GradientDiv />
                <div className='max-w-screen-xl mobile:p-4 mt-20 mobile:mt-6 mb-16 flex flex-wrap mobile:flex-col items-center justify-between mx-auto p-6'>
                    <p className='text-[48px] mobile:text-[36px] font-lora text-primary font-[700] leading-tight'>
                        <span className='text-secondary'> Empowering </span> Your Trading Journey:<br />
                        Our Comprehensive <span className='text-secondary'>Services for Success</span>
                    </p>
                    <p className='text-lg font-[400] text-primary font-poppins leading-tight w-[93%] mobile:w-full mobile:text-justify mt-5'>Experience the difference with TFS and TIC—where trading education meets personal transformation. With a focus on both
                        market expertise and personal development, our programs are designed to empower you to achieve your goals and thrive
                        in the world of trading. Join us today and embark on a journey towards lasting success and fulfilment</p>
                </div>
                <div className="w-full bg-secondary relative overflow-hidden mobile:">
                    <div className="max-w-screen-xl py-16 px-10 flex h-full flex-wrap items-center justify-between mx-auto relative">
                        {/* Large Number for Mobile */}
                        <div className="mobile:absolute mobile:left-2 mobile:top-10 mobile:z-0 laptop:hidden">
                            <p className="mobile:text-[150px] mobile:leading-[100px] text-[400px] font-[700] text-tertiary leading-[342px]  mobile:block">
                                1
                            </p>
                        </div>

                        <div className="w-full flex gap-14 h-full items-center mobile:flex-col mobile:relative mobile:gap-5">
                            {/* Large Number for Larger Screens */}
                            <div className="w-[20%] flex justify-center mobile:hidden tablet:hidden">
                                <p className="text-[400px] font-[700] text-tertiary leading-[342px]">
                                    1
                                </p>
                            </div>

                            {/* Text Content */}
                            <div className="w-[50%] flex flex-col gap-3 mobile:w-full relative mobile:z-10">
                                <p className="text-[24px] font-[700] text-primary mobile:text-[20px] relative mobile:z-10">
                                    Voice Nuggets
                                </p>
                                <p className="text-[36px] font-[700] text-primary mobile:text-[24px] relative mobile:z-10">
                                    Discover Nishant Arora's Insights: Market Strategies, Life Lessons, and More!
                                </p>
                                <p className="text-[18px] font-400 font-poppins text-primary mobile:text-[16px] relative mobile:z-10">
                                    A sneak peak into Nishant Arora's mind with the audio nuggets on markets, life, psychology, and much more.
                                </p>
                            </div>

                            {/* Image */}
                            <div className='w-[30%] mobile:w-full'>
                                <div className=' w-full   h-[360px] mobile:w-full bg-white rounded-[35px] ' >
                                    <img src='https://tfspublic.s3.ap-south-1.amazonaws.com/images/Home+Page/Voive+Nuggets.png' alt='' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="w-full bg-tertiary relative overflow-hidden mobile:">
                    <div className="max-w-screen-xl py-16 px-10 flex h-full flex-wrap items-center justify-between mx-auto relative">
                        {/* Large Number for Mobile */}
                        <div className="mobile:absolute mobile:left-2 mobile:top-10 mobile:z-0 laptop:hidden">
                            <p className="mobile:text-[150px] mobile:leading-[100px] text-[400px] font-[700] text-secondary leading-[342px]  mobile:block">
                                2
                            </p>
                        </div>

                        <div className="w-full flex gap-14 h-full items-center mobile:flex-col mobile:relative mobile:gap-5">
                            {/* Large Number for Larger Screens */}
                            <div className="w-[20%] flex justify-center mobile:hidden tablet:hidden">
                                <p className="text-[400px] font-[700] text-secondary leading-[342px]">
                                    2
                                </p>
                            </div>

                            {/* Text Content */}
                            <div className="w-[50%] flex flex-col gap-3 mobile:w-full relative mobile:z-10">
                                <p className="text-[24px] font-[700] text-primary mobile:text-[20px] relative mobile:z-10">
                                    Multi Time Frame Analysis
                                </p>
                                <p className="text-[36px] font-[700] text-primary mobile:text-[24px] relative mobile:z-10">
                                    Unlock Your Trading Potential: Harnessing Market Structure
                                    with Multi-Timeframe Analysis
                                </p>
                                <p className="text-[18px] font-400 font-poppins text-primary mobile:text-[16px] relative mobile:z-10">
                                    Multi time frame analysis is the key to success, We deep dive
                                    in the building blocks i.e Market Structure
                                </p>
                            </div>

                            {/* Image */}
                            <div className='w-[30%] mobile:w-full'>
                                <div className=' w-full   h-[360px] mobile:w-full bg-white rounded-[35px] ' >
                                    <img src='https://tfspublic.s3.ap-south-1.amazonaws.com/images/Home+Page/Multi_time_frame.png' alt='' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="w-full bg-secondary relative overflow-hidden mobile:">
                    <div className="max-w-screen-xl py-16 px-10 flex h-full flex-wrap items-center justify-between mx-auto relative">
                        {/* Large Number for Mobile */}
                        <div className="mobile:absolute mobile:left-2 mobile:top-10 mobile:z-0 laptop:hidden">
                            <p className="mobile:text-[150px] mobile:leading-[100px] text-[400px] font-[700] text-tertiary leading-[342px]  mobile:block">
                                3
                            </p>
                        </div>

                        <div className="w-full flex gap-14 h-full items-center mobile:flex-col mobile:relative mobile:gap-5">
                            {/* Large Number for Larger Screens */}
                            <div className="w-[20%] flex justify-center mobile:hidden tablet:hidden">
                                <p className="text-[400px] font-[700] text-tertiary leading-[342px]">
                                    3
                                </p>
                            </div>

                            {/* Text Content */}
                            <div className="w-[50%] flex flex-col gap-3 mobile:w-full relative mobile:z-10">
                                <p className="text-[24px] font-[700] text-primary mobile:text-[20px] relative mobile:z-10">
                                    Real Time Charts
                                </p>
                                <p className="text-[36px] font-[700] text-primary mobile:text-[24px] relative mobile:z-10">
                                    Stay Ahead of the Markets: Dive Deep into Real-Time Trading
                                    Snippets at TIC
                                </p>
                                <p className="text-[18px] font-400 font-poppins text-primary mobile:text-[16px] relative mobile:z-10">
                                    Real time trading snippets are discussed in great length at
                                    TIC.
                                </p>
                            </div>

                            {/* Image */}
                            <div className='w-[30%] mobile:w-full'>
                                <div className=' w-full   h-[360px] mobile:w-full bg-white rounded-[35px] ' >
                                    <img src='https://tfspublic.s3.ap-south-1.amazonaws.com/images/Home+Page/Multi_time_frame.png' alt='' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="w-full bg-tertiary relative overflow-hidden mobile:">
                    <div className="max-w-screen-xl py-16 px-10 flex h-full flex-wrap items-center justify-between mx-auto relative">
                        {/* Large Number for Mobile */}
                        <div className="mobile:absolute mobile:left-2 mobile:top-10 mobile:z-0 laptop:hidden">
                            <p className="mobile:text-[150px] mobile:leading-[100px] text-[400px] font-[700] text-secondary leading-[342px]  mobile:block">
                                4
                            </p>
                        </div>

                        <div className="w-full flex gap-14 h-full items-center mobile:flex-col mobile:relative mobile:gap-5">
                            {/* Large Number for Larger Screens */}
                            <div className="w-[20%] flex justify-center mobile:hidden tablet:hidden">
                                <p className="text-[400px] font-[700] text-secondary leading-[342px]">
                                    4
                                </p>
                            </div>

                            {/* Text Content */}
                            <div className="w-[50%] flex flex-col gap-3 mobile:w-full relative mobile:z-10">
                                <p className="text-[24px] font-[700] text-primary mobile:text-[20px] relative mobile:z-10">
                                    Live Trades
                                </p>
                                <p className="text-[36px] font-[700] text-primary mobile:text-[24px] relative mobile:z-10">
                                    Become a Market Expert: Join Our Mastering Intraday and
                                    Positional Trading
                                </p>
                                <p className="text-[18px] font-400 font-poppins text-primary mobile:text-[16px] relative mobile:z-10">
                                    Our 1800+ current students actively trade be it intraday
                                    swings or weekly positional using market structure.
                                </p>
                            </div>

                            {/* Image */}
                            <div className='w-[30%] mobile:w-full'>
                                <div className=' w-full   h-[360px] mobile:w-full bg-white rounded-[35px] ' >
                                    <img src='https://tfspublic.s3.ap-south-1.amazonaws.com/images/Home+Page/live_trades.png' alt='' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='max-w-screen-xl mt-20 mobile:mt-5 mb-20 flex mobile:flex-col flex-wrap items-center justify-between mx-auto p-6'>
                    <div className="w-full mobile:flex-col flex gap-14">
                        <div className="w-[53%] mobile:w-full">
                            <p className='text-h5 font-[700] text-primary mb-3 font-lora'>Why choose us</p>
                            <p className='text-h2 mobile:text-[36px] font-lora font-[700] text-secondary'> How TIC Cultivates a
                                Culture of Achievement</p>
                            <hr className='w-[15%] mobile:w-[23%] mt-5 border-[2px] border-[#FFBB6B] mb-5 ' />
                            <p className='text-[18px] leading-[24px] mobile:text-justify font-[400] font-poppins text-primary'>Reflecting on childhood, it's a common observation that in school,
                                those who challenged authority and prioritized socializing over
                                studies tended to gravitate towards each other, while studious
                                individuals formed their own groups, focusing on learning and
                                academic pursuits. This tendency to emulate peers begins early
                                in life, shaping our behaviours and attitudes over time. The idea
                                that success breeds success is fundamental to the establishment
                                of TIC by NA, which aims to foster an environment where
                                achievement is nurtured.</p>

                            <div className='flex flex-wrap items-center gap-5 mt-10'>
                                <Link to={"/contactus"} className=" py-3 mobile:py-5 text-primary flex justify-center mobile:w-full text-body items-center font-[600] text-[20px] font-poppins px-5 border-[3px] border-primary rounded-[35px]">Contact Us</Link>
                                <p
                                    className="py-5 mobile:w-full text-primary flex justify-center text-body items-center font-[600] text-[20px] font-poppins getInTouch px-10"
                                    onClick={() => setmodal(true)}
                                    style={{ cursor: 'pointer' }}
                                >
                                    Download Brochure
                                </p>
                            </div>
                        </div>
                        <div className="w-[47%] mobile:w-full">

                            <img src={chhoseus} alt=' ' className=' relative' />

                        </div>
                    </div>
                </div>
                <PricingPlan />
                <div className='max-w-screen-xl py-16 px-10 mobile:px-5 flex h-full flex-wrap items-center justify-between mx-auto'>
                    <div className='w-full flex mobile:flex-col'>
                        <div className='w-[83%] mobile:w-full'>
                            <p className='text-h2 mobile:text-h4 font-bold font-lora text-primary'>Dive Deep into Trading Insights:<br />
                                <span className='text-secondary'> Subscribe</span> to Our YouTube Channel Now!</p>
                            <p className='text-body font-poppins text-primary mt-3 mobile:mt-5'>Welcome to our YouTube channel, where we're dedicated to helping you unlock the secrets to trading success.
                                Subscribe now to join our growing community of traders and take your skills to the next level!</p>
                        </div>
                        <div className='w-[17%] mobile:w-full mobile:mt-10'>

                            <a href='https://www.youtube.com/@WelcomeToTFS' className=" py-4 text-primary mobile:w-full flex justify-center text-body items-center font-[600] text-[20px] font-poppins cursor-pointer getInTouch px-5" >Subscribe Now</a>
                            <Link to={"/resources"}>  <p className=" py-3  text-primary mobile:w-full mt-5 flex justify-center text-body items-center font-[600] text-[20px] font-poppins px-5 border-[3px] border-primary rounded-[35px]">View All Videos</p>
                            </Link>
                        </div>
                    </div>
                </div>
                <Carousel />
                <Testimonials />
                <Blogs />

            </div>
        </>
    )
}

export default Homepage
