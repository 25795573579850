import React, { useState } from 'react';
import Pagination from './Pagination';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const PodCast = ({ galaryData, loader }) => {
    const [loadingVideos, setLoadingVideos] = useState({});
    const youtubeBaseUrl = 'https://www.youtube.com/embed/';
    const filteredGalleryList = galaryData?.galleryList?.filter(item =>
        item.externalLink && item.externalLink.startsWith(youtubeBaseUrl)
    );



    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 21;

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredGalleryList?.slice(indexOfFirstItem, indexOfLastItem);

    const paginate = pageNumber => {
        setCurrentPage(pageNumber);
        window.scrollTo(0, 0);
    };

    const handleVideoLoad = (id) => {
        setLoadingVideos(prev => ({ ...prev, [id]: false }));
    };

    return (
        <>
            {loader ? (
                <div className='grid grid-cols-3 tablet:grid-cols-2 flex-wrap  mobile:grid-cols-1 gap-14 w-full'>
                    {[...Array(6)].map((_, i) => (
                        <div key={i} className='rounded-[32px] border-[1px] glassbg2 border-white px-3 py-3'>
                            <Skeleton width={200} height={20} />
                            <Skeleton width={150} height={20} className="mt-2 mb-2" />
                            <Skeleton height={270} borderRadius="30px" />
                        </div>
                    ))}
                </div>
            ) : (
                <>
                    {currentItems?.length > 0 ? (
                        <>
                            <div className='grid grid-cols-3 tablet:grid-cols-2 flex-wrap mobile:grid-cols-1 gap-14 w-full'>
                                {currentItems.map((item) => (
                                    <div key={item.galleryId} className='rounded-[32px] border-[1px] glassbg2 border-white px-3 py-3'>
                                        <div className='flex flex-col justify-between h-full'>
                                            <div>
                                                <p className='text-h5 text-primary font-bold'>{item.title}</p>
                                                <p className='text-body font-poppins font-medium text-primary mt-3 mb-3'>
                                                    Created on: {item.createdDate}
                                                </p>
                                            </div>
                                            <div className="relative">
                                                <iframe
                                                    src={item?.externalLink}
                                                    frameBorder="0"
                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                    allowFullScreen
                                                    className='min-h-[200px]  h-[200px] max-h-[250px] object-cover'
                                                    title="YouTube video"
                                                    onLoad={() => handleVideoLoad(item.galleryId)}
                                                    style={{
                                                        width: '100%',
                                                        height: '270px',
                                                        borderRadius: '30px',
                                                        display: 'block'
                                                    }}
                                                ></iframe>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>

                            <Pagination
                                currentPage={currentPage}
                                totalPages={Math.ceil(filteredGalleryList?.length / itemsPerPage)}
                                paginate={paginate}
                            />
                        </>
                    ) : (
                        <div>No Data Found Podcast</div>
                    )}
                </>
            )}
        </>
    );
};

export default PodCast;
