import React, { useState, useEffect } from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import Pagination from './Pagination';
import images1 from "../../Images/images1.png";

const ImagesRes = ({ galaryData, loader }) => {
    const [currentIndexes, setCurrentIndexes] = useState({});
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 21;

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentIndexes((prevIndexes) => {
                const newIndexes = { ...prevIndexes };
                galaryData?.galleryList?.forEach((item) => {
                    const currentIndex = newIndexes[item.galleryId] ?? 0;
                    const totalImages = item?.attachments?.length || 1;
                    newIndexes[item.galleryId] = (currentIndex + 1) % totalImages;
                });
                return newIndexes;
            });
        }, 3000); // Change image every 3 seconds
        return () => clearInterval(interval);
    }, [galaryData]);

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = galaryData?.galleryList?.slice(indexOfFirstItem, indexOfLastItem);

    const paginate = pageNumber => {
        setCurrentPage(pageNumber);
        window.scrollTo(0, 0);
    };

    return (
        <>
            {loader ? (
                <div className='grid grid-cols-3 mobile:grid-cols-1 gap-14 w-full'>
                    {[...Array(6)].map((_, i) => (
                        <div key={i} className='rounded-[32px] border-[1px] glassbg2 border-white px-3 py-3'>
                            <Skeleton width={200} height={20} />
                            <Skeleton className="mt-3" width="100%" height={200} />
                        </div>
                    ))}
                </div>
            ) : (
                <>
                    {currentItems?.length > 0 ? (
                        <>
                            <div className='grid grid-cols-3 tablet:grid-cols-2 flex-wrap mobile:grid-cols-1 gap-14 w-full'>
                                {currentItems?.map((item) => (
                                    <div key={item.galleryId} className='rounded-[32px] border-[1px] glassbg2 border-white px-3 py-3'>
                                        <div>
                                            <p className='text-h5 text-primary font-bold'>
                                                {item.title}
                                            </p>
                                            <div className="relative mt-3 w-full h-auto rounded-[20px] overflow-hidden">
                                                {(item.attachments && item.attachments.length > 0) ? (
                                                    item.attachments.map((url, index) => (
                                                        <img
                                                            key={index}
                                                            src={url}
                                                            alt={item.title}
                                                            className={` top-0 left-0 max-h-[250px] object-cover w-full h-full rounded-[20px] transition-opacity duration-1000 ${currentIndexes[item.galleryId] === index ? 'opacity-100' : 'opacity-0'
                                                                }`}
                                                        />
                                                    ))
                                                ) : (
                                                    <img
                                                        src={images1}
                                                        alt="Default"
                                                        className="w-full h-auto rounded-[20px]"
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <Pagination
                                currentPage={currentPage}
                                totalPages={Math.ceil(galaryData?.galleryList?.length / itemsPerPage)}
                                paginate={paginate}
                            />
                        </>
                    ) : (
                        <div>No Data Found</div>
                    )}
                </>
            )}
        </>
    );
};

export default ImagesRes;
