import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import prevBtn from "../../Images/prev.svg";
import nextBtn from "../../Images/next.svg";
import tube from "../../Images/tube.svg";
import rightcoma from "../../Images/leftcoma.svg";
import leftcoma from "../../Images/rightcoma.svg";
import { getAllTestimonials } from '../../store/actions/website.action';

const Testimonials = () => {
    const [currentTestimonialIndex, setCurrentTestimonialIndex] = useState(0);
    const { testimonialList } = useSelector((state) => state?.web);
    const dispatch = useDispatch();

    useEffect(() => {
        const payload = {
            userId: "",
            pageNo: 1,
            perPageResults: 100
        };
        dispatch(getAllTestimonials(payload));
    }, [dispatch]);

    const handlePrev = () => {
        setCurrentTestimonialIndex((prevIndex) =>
            prevIndex === 0 ? testimonialList?.testimonialsList.length - 1 : prevIndex - 1
        );
    };

    const handleNext = () => {
        setCurrentTestimonialIndex((prevIndex) =>
            prevIndex === testimonialList?.testimonialsList.length - 1 ? 0 : prevIndex + 1
        );
    };

    return (
        <div className="w-full h-full min-h-[650px] mobile:h-full mobile:bg-none relative bg-cover bg-no-repeat bg-body-bg mt-20 justify-center flex flex-col text-center items-center">
            <div className='relative'>
                <img src={tube} alt='' className='absolute mt-8 -ml-28' />
                <p className='text-[56px] mobile:text-[36px] z-10 text-primary font-[700] leading-tight font-lora mt-8 relative'>
                    What our Students <br /> Say About Us
                </p>
            </div>
            <div className=' w-[57%] mobile:w-[90%] relative'>
                <img src={'https://tfspublic.s3.ap-south-1.amazonaws.com/images/pricing/comma1.png'} alt='' className='absolute w-20 -mt-8 mobile:hidden' />
                <div className='rounded-[60px] mobile:rounded-[40px] bg-tertiary min-h-[358px] mobile:h-full mt-7 relative '>
                    {testimonialList?.testimonialsList?.length > 0 && (
                        <p className='px-[95px] mobile:px-7 mobile:py-7 mobile:text-[24px] flex justify-center items-center text-center mobile:text-justify py-[53px] text-h5 text-primary leading-[42px] font-poppins font-[300]'>
                            {testimonialList?.testimonialsList[currentTestimonialIndex]?.message}
                        </p>
                    )}
                    <img src={'https://tfspublic.s3.ap-south-1.amazonaws.com/images/pricing/comma.png'} alt='' className='absolute -mt-36 w-20 right-4 mobile:hidden' />
                </div>
            </div>
            <div className='flex gap-5 mt-7'>
                <img src={prevBtn} alt='' className='cursor-pointer' onClick={handlePrev} />
                {testimonialList?.testimonialsList?.length > 0 && (
                    <button className='bg-secondary text-primary text-[20px] mobile:px-10 px-[70px] rounded-[60px] font-[700]'>
                        {testimonialList?.testimonialsList[currentTestimonialIndex]?.userName}
                    </button>
                )}
                <img src={nextBtn} alt='' className='cursor-pointer' onClick={handleNext} />
            </div>
        </div>
    );
}

export default Testimonials;
