import React, { useEffect, useState } from "react";
import loginImg from "../Images/loginImg.jpg";
import * as Yup from "yup";
import { useFormik } from "formik";
import logo from "../Images/logo.svg";
import { useDispatch, useSelector } from "react-redux";
import { VerifyUserAct } from "../store/actions/auth.action";
import toast from "react-hot-toast";
import FirstLogin from "../components/FirstLogin";
import { Link, redirect, useNavigate } from "react-router-dom";
import ResetPwdModal from "../components/ResetPwdModal";
import ForceLogin from "../components/ForceLogin";

const validationSchema = Yup.object().shape({
    newpassword: Yup.string().when("nextStep", {
        is: "newPassword",
        then: Yup.string()
            .required("New Password is required")
            .matches(
                /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
                "Password must be at least 8 characters long, contain 1 digit, 1 alphabet, and 1 special character"
            ),
    }),
    confirmpassword: Yup.string().when("nextStep", {
        is: "newPassword",
        then: Yup.string()
            .required("Confirm Password is required")
            .oneOf([Yup.ref("newpassword"), null], "Passwords must match"),
    }),
});

const Login = () => {
    const [loginOption, setLoginOption] = useState("unique");
    const [isVerified, setIsVerified] = useState(false);
    const [resetPassword, setresetpassword] = useState(false);
    const [firstLogin, setFirstLogin] = useState(false);
    const [otptext, setotptext] = useState(null);
    const { userError, loader } = useSelector((state) => state.auth);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [nextSteptype, setnextsteptype] = useState("initLogin");
    const [popuptype, setPopuptype] = useState();
    const [passwordCriteria, setPasswordCriteria] = useState({
        length: false,
        digit: false,
        alphabet: false,
        specialChar: false,
    });
    const handleforcelogin = () => {
        let payload = {};
        if (loginOption === "unique") {
            payload = {
                uniqueId: formik?.values?.uniqueId,
                password: formik?.values.password,
                grantType: "token-force",
            };
        } else if (loginOption === "email") {
            payload = {
                emailId: formik?.values.email,
                password: formik?.values.password,
                grantType: "token-force",
            };
        } else if (loginOption === "mobile") {
            payload = {
                mobileNo: formik?.values.mobile,
                password: formik?.values.password,
                grantType: "token-force",
            };
        }
        dispatch(
            VerifyUserAct(payload, (response) => {
                console.log("checkuser", response);
                if (response?.userInfo) {
                    setPopuptype();
                    localStorage?.setItem(
                        "userInfo",
                        JSON?.stringify(response?.userInfo)
                    );
                    localStorage?.setItem("token", response?.accessToken);
                    localStorage?.setItem(
                        "availableSubscriptionPlans",
                        JSON?.stringify(response?.availableSubscriptionPlans)
                    );
                    localStorage?.setItem(
                        "refreshToken",
                        JSON?.stringify(response?.refreshToken)
                    );
                    if (response?.userInfo?.kycVerified == false) {
                        navigate("/profile");
                        toast.error("Please complete your kyc first");
                    } else {

                        navigate("/insiescreen");
                    }
                }
            })
        );
    };
    const handleResetPwd = () => {
        let payload = {};
        if (loginOption === "unique") {
            payload = {
                emailId: "",
                uniqueId: formik?.values?.uniqueId,
                mobileNo: "",
                grantType: "resetPassword",
            };
        } else if (loginOption === "email") {
            payload = {
                emailId: formik?.values?.email,
                uniqueId: "",
                mobileNo: "",
                grantType: "resetPassword",
            };
        } else if (loginOption === "mobile") {
            payload = {
                emailId: "",
                uniqueId: "",
                mobileNo: formik?.values?.mobile,
                grantType: "resetPassword",
            };
        }
        dispatch(
            VerifyUserAct(payload, (response) => {
                console.log("checkuser", response);
                if (response?.data?.nextStep === "validateOtp") {
                    setPopuptype();
                    setnextsteptype("validateOtppage");
                }
            })
        );
    };
    const formik = useFormik({
        initialValues: {
            loginOption: "unique",
            uniqueId: "",
            email: "",
            mobile: "",
            userId: "",
            password: "",
            otp: "",
            newpassword: "",
            confirmpassword: "",
        },
        validationSchema,
        onSubmit: (values) => {
            if (nextSteptype === "initLogin") {
                let payload = {};
                if (loginOption === "unique") {
                    payload = {
                        emailId: "",
                        uniqueId: values.uniqueId,
                        mobileNo: "",
                        grantType: "checkUser",
                    };
                } else if (loginOption === "email") {
                    payload = {
                        emailId: values.email || "",
                        uniqueId: "",
                        mobileNo: "",
                        grantType: "checkUser",
                    };
                } else if (loginOption === "mobile") {
                    payload = {
                        emailId: "",
                        uniqueId: "",
                        mobileNo: values.mobile || "",
                        grantType: "checkUser",
                    };
                }
                dispatch(
                    VerifyUserAct(payload, (response) => {
                        console.log("checkuser", response);
                        if (response?.data?.nextStep === "token") {
                            setnextsteptype("Passwordcheck");
                            formik.setFieldValue(
                                "userId",
                                values.uniqueId || values.email || values.mobile
                            );
                        } else if (response?.data?.nextStep === "validateOtp") {
                            setPopuptype("firstLogin");
                            setnextsteptype("validateOtppage");
                        }
                    })
                );
            }
            if (nextSteptype === "Passwordcheck") {
                let payload = {};
                if (loginOption === "unique") {
                    payload = {
                        uniqueId: values.uniqueId,
                        password: values.password,
                        grantType: "token",
                    };
                } else if (loginOption === "email") {
                    payload = {
                        emailId: values.email,
                        password: values.password,
                        grantType: "token",
                    };
                } else if (loginOption === "mobile") {
                    payload = {
                        mobileNo: values.mobile,
                        password: values.password,
                        grantType: "token",
                    };
                }
                dispatch(
                    VerifyUserAct(payload, (response) => {
                        console.log("checkuser", response);
                        // nextStep: "resetPassword"
                        if (response?.response?.data?.data?.nextStep === "resetPassword") {
                            setPopuptype("resetpassword");
                        } else if (
                            response?.response?.data?.data === "Session already exist"
                        ) {
                            setPopuptype("forceLogin");
                        } else if (response?.userInfo) {
                            setPopuptype();
                            localStorage?.setItem(
                                "userInfo",
                                JSON?.stringify(response?.userInfo)
                            );
                            localStorage?.setItem("token", response?.accessToken);
                            localStorage?.setItem(
                                "availableSubscriptionPlans",
                                JSON?.stringify(response?.availableSubscriptionPlans)
                            );
                            localStorage?.setItem(
                                "refreshToken",
                                JSON?.stringify(response?.refreshToken)
                            );
                            if (response?.userInfo?.kycVerified == false) {
                                navigate("/profile");
                                toast.error("Please complete your kyc first");
                            } else {

                                navigate("/insiescreen");
                            }
                        }
                    })
                );
            }
            if (nextSteptype === "validateOtppage") {
                let payload = {};
                if (loginOption === "unique") {
                    payload = {
                        uniqueId: formik?.values?.uniqueId,
                        grantType: "validateOtp",
                        otp: formik?.values?.otp,
                    };
                } else if (loginOption === "email") {
                    payload = {
                        emailId: formik?.values?.uniqueId,
                        grantType: "validateOtp",
                        otp: formik?.values?.otp,
                    };
                } else if (loginOption === "mobile") {
                    payload = {
                        mobileNo: formik?.values?.mobile,
                        grantType: "validateOtp",
                        otp: formik?.values?.otp,
                    };
                }
                dispatch(
                    VerifyUserAct(payload, (response) => {
                        console.log("checkuser", response);
                        // nextStep: "resetPassword"
                        if (response?.data?.nextStep === "changePassword") {
                            setnextsteptype("resetpasswordpage");
                        }
                    })
                );
            }
            if (nextSteptype === "resetpasswordpage") {
                let payload = {};
                if (loginOption === "unique")
                    payload = {
                        grantType: "changePassword",
                        newPassword: values?.newpassword,
                        uniqueId: values?.uniqueId,
                        otp: values?.otp,
                    };
                dispatch(
                    VerifyUserAct(payload, (response) => {
                        console.log("changepassword", response);
                        if (response?.data?.nextStep === "checkUser") {
                            formik.setFieldValue("password", "");
                            setnextsteptype("initLogin");
                        }
                    })
                );
            }
            if (
                nextSteptype === "resetpasswordpage" &&
                values.newpassword !== values.confirmpassword
            ) {
                toast.error("Passwords do not match.");
                return;
            }
        },
    });
    const handleRadioChange = (event) => {
        setLoginOption(event.target.value);
        formik.setFieldValue("loginOption", event.target.value);
    };

    useEffect(() => {
        const { newpassword } = formik.values;
        setPasswordCriteria({
            length: newpassword.length >= 8,
            digit: /[0-9]/.test(newpassword),
            alphabet: /[A-Za-z]/.test(newpassword),
            specialChar: /[@$!%*#?&]/.test(newpassword),
        });
    }, [formik.values.newpassword]);
    console.log("popuptype", popuptype, nextSteptype);
    return (
        <>
            {popuptype === "firstLogin" && <FirstLogin setPopuptype={setPopuptype} />}
            {popuptype === "forceLogin" && (
                <ForceLogin
                    setPopuptype={setPopuptype}
                    handleforcelogin={handleforcelogin}
                />
            )}
            {popuptype === "resetpassword" && (
                <ResetPwdModal
                    setresetpassword={setresetpassword}
                    handleResetPwd={handleResetPwd}
                />
            )}
            <div className="laptop:bg-[url('./Images/bglogin.svg')] flex mobile:flex-col mobile:min-h-screen h-screen">
                {/* Left: Image Section */}
                <div className="w-1/2 mobile:w-full h-screen hidden lg:block">
                    <img
                        src={loginImg}
                        alt="leftImg"
                        className="object-cover object-center mobile:w-[90%] tablet:w-[70%] w-full h-full"
                    />
                </div>

                {/* Right: Login Form */}
                <div className="     lg:w-1/2 mobile:min-h-screen lpatop:flex flex-col">
                    <header className="mobile:bg-white mobile:shadow-md mobile:flex mobile:justify-between p-4 lg:p-7 mobile:items-center flex justify-end ">
                        <div className="flex items-center laptop:hidden">
                            <img src={logo} alt="Logo" className="h-10" />
                        </div>
                        <div className="border-2 mobile:px-3  laptop:flex justify-end cursor-pointer border-primary rounded-[57px] px-2 laptop:p-4 mobile:py-3 laptop:px-9 text-primary text-center laptop:justify-end font-poppins text-lg laptop:text-xl font-medium  ">
                            <Link to="/home">Go to Website</Link>
                        </div>
                    </header>

                    <div className="laptop:h-[77vh] flex flex-col laptop:gap-5 items-center p-4 mobile:bg-tertiary mobile:items-start lg:p-0">
                        <img src={logo} alt="" className="w-[200px] lg:w-[354px] mobile:hidden mb-5" />
                        <h1 className="text-3xl lg:text-5xl text-primary font-semibold mobile:text-start mobile:text-5xl mobile:mb-5 mt-5 font-lora text-center">
                            Welcome To TFS
                        </h1>
                        <p className="text-primary font-poppins mobile:mb-5 text-lg lg:text-2xl text-center">
                            Please Select Login Options
                        </p>
                        <form
                            onSubmit={formik.handleSubmit}
                            className="w-full mobile:mb-5 lg:w-[60%] px-2 lg:px-0"
                        >
                            {nextSteptype === "initLogin" && (
                                <>
                                    <div className="flex flex-wrap lg:flex-nowrap gap-4 mb-3 mobile:justify-start justify-center lg:justify-start">
                                        <div className="flex items-center">
                                            <input
                                                id="unique-radio"
                                                type="radio"
                                                value="unique"
                                                name="colored-radio"
                                                className="w-6 h-6 lg:w-7 lg:h-7 text-yellow-400 bg-transparent border-primary focus:ring-0 focus:outline-none focus:border-primary"
                                                onChange={handleRadioChange}
                                                defaultChecked
                                            />
                                            <label
                                                htmlFor="unique-radio"
                                                className="ml-2 text-sm lg:text-base font-poppins font-normal mobile:text-lg text-primary"
                                            >
                                                Unique ID
                                            </label>
                                        </div>
                                        <div className="flex items-center">
                                            <input
                                                id="email-radio"
                                                type="radio"
                                                value="email"
                                                name="colored-radio"
                                                className="w-6 h-6 lg:w-7 lg:h-7 text-yellow-400 bg-transparent border-primary focus:ring-0 focus:outline-none focus:border-primary"
                                                onChange={handleRadioChange}
                                            />
                                            <label
                                                htmlFor="email-radio"
                                                className="ml-2 text-sm lg:text-base font-poppins mobile:text-lg font-normal text-primary"
                                            >
                                                Email ID
                                            </label>
                                        </div>
                                        <div className="flex mobile:mb-5 mobile:mt-3 items-center">
                                            <input
                                                id="mobile-radio"
                                                type="radio"
                                                value="mobile"
                                                name="colored-radio"
                                                className="w-6 h-6 lg:w-7 lg:h-7 text-yellow-400 bg-transparent border-primary focus:ring-0 focus:outline-none focus:border-primary"
                                                onChange={handleRadioChange}
                                            />
                                            <label
                                                htmlFor="mobile-radio"
                                                className="ml-2 text-sm lg:text-base font-poppins mobile:text-lg font-normal text-primary"
                                            >
                                                Mobile Number
                                            </label>
                                        </div>
                                    </div>
                                    <div className="mb-3">
                                        {loginOption === "unique" && (
                                            <>
                                                <input
                                                    type="text"
                                                    placeholder="Enter Unique ID"
                                                    className="w-full px-4 py-2 lg:py-3 rounded-[10px] mobile:rounded-[40px] mobile:py-4 lg:rounded-[13px] placeholder-primary bg-transparent border-2 border-primary focus:outline-none focus:border-primary"
                                                    name="uniqueId"
                                                    onChange={formik.handleChange}
                                                    value={formik.values.uniqueId}
                                                />
                                                {formik.touched.uniqueId && formik.errors.uniqueId && (
                                                    <div className="text-red-500 mt-2">
                                                        {formik.errors.uniqueId}
                                                    </div>
                                                )}
                                            </>
                                        )}
                                        {loginOption === "email" && (
                                            <>
                                                <input
                                                    type="email"
                                                    placeholder="Enter Email ID"
                                                    className="w-full px-4 py-2 lg:py-3 mobile:rounded-[40px] mobile:py-4 rounded-[10px] lg:rounded-[13px] placeholder-primary bg-transparent border-2 border-primary focus:outline-none focus:border-primary"
                                                    name="email"
                                                    onChange={formik.handleChange}
                                                    value={formik.values.email}
                                                />
                                                {formik.touched.email && formik.errors.email && (
                                                    <div className="text-red-500 mt-2">
                                                        {formik.errors.email}
                                                    </div>
                                                )}
                                            </>
                                        )}
                                        {loginOption === "mobile" && (
                                            <>
                                                <input
                                                    type="text"
                                                    placeholder="Enter Mobile Number"
                                                    className="w-full px-4 py-2 mobile:rounded-[40px] mobile:py-4 lg:py-3 rounded-[10px] lg:rounded-[13px] placeholder-primary bg-transparent border-2 border-primary focus:outline-none focus:border-primary"
                                                    name="mobile"
                                                    onChange={formik.handleChange}
                                                    value={formik.values.mobile}
                                                />
                                                {formik.touched.mobile && formik.errors.mobile && (
                                                    <div className="text-red-500 mt-2">
                                                        {formik.errors.mobile}
                                                    </div>
                                                )}
                                            </>
                                        )}
                                    </div>
                                    <button
                                        type="submit"
                                        className="w-full px-4 py-2 lg:py-3 mt-4 font-semibold text-white bg-secondary rounded-[10px] mobile:rounded-[40px] mobile:py-4 lg:rounded-[13px] uppercase"
                                    >
                                        {loader ? "loading..." : "Verify User ID"}
                                    </button>
                                </>
                            )}
                            {nextSteptype === "validateOtppage" && (
                                <>
                                    <div className="mb-3">
                                        <input
                                            type="text"
                                            placeholder="Enter OTP"
                                            className="w-full px-4 py-2 lg:py-3 rounded-[10px] lg:rounded-[13px] placeholder-primary bg-transparent border-2 border-primary focus:outline-none focus:border-primary"
                                            name="otp"
                                            onChange={formik.handleChange}
                                            value={formik.values.otp}
                                        />
                                        {formik.touched.otp && formik.errors.otp && (
                                            <div className="text-red-500 mt-2">
                                                {formik.errors.otp}
                                            </div>
                                        )}
                                        <button
                                            type="submit"
                                            className="w-full px-4 py-2 lg:py-3 mt-4 font-semibold text-white bg-secondary rounded-[10px] lg:rounded-[13px] uppercase"
                                        >
                                            {loader ? "loading..." : "check otp"}
                                        </button>
                                    </div>
                                </>
                            )}
                            {nextSteptype === "resetpasswordpage" && (
                                <>
                                    <div className="mb-3">
                                        <input
                                            type="password"
                                            placeholder="Enter New Password"
                                            className="w-full px-4 py-2 lg:py-3 rounded-[10px] lg:rounded-[13px] placeholder-primary bg-transparent border-2 border-primary focus:outline-none focus:border-primary"
                                            name="newpassword"
                                            onChange={formik.handleChange}
                                            value={formik.values.newpassword}
                                        />
                                        {formik.touched.newpassword &&
                                            formik.errors.newpassword && (
                                                <div className="text-red-500 mt-2">
                                                    {formik.errors.newpassword}
                                                </div>
                                            )}
                                    </div>
                                    <div className="mb-3">
                                        <input
                                            type="password"
                                            placeholder="Confirm New Password"
                                            className="w-full px-4 py-2 lg:py-3 rounded-[10px] lg:rounded-[13px] placeholder-primary bg-transparent border-2 border-primary focus:outline-none focus:border-primary"
                                            name="confirmpassword"
                                            onChange={formik.handleChange}
                                            value={formik.values.confirmpassword}
                                        />
                                        {formik.touched.confirmpassword &&
                                            formik.errors.confirmpassword && (
                                                <div className="text-red-500 mt-2">
                                                    {formik.errors.confirmpassword}
                                                </div>
                                            )}
                                    </div>
                                    <ul className="password-criteria text-primary">
                                        <li
                                            className={
                                                passwordCriteria.length
                                                    ? "text-green-500"
                                                    : "text-red-600"
                                            }
                                        >
                                            At least 8 characters long
                                        </li>
                                        <li
                                            className={
                                                passwordCriteria.digit
                                                    ? "text-green-500"
                                                    : "text-red-600"
                                            }
                                        >
                                            Contains at least 1 digit
                                        </li>
                                        <li
                                            className={
                                                passwordCriteria.alphabet
                                                    ? "text-green-500"
                                                    : "text-red-600"
                                            }
                                        >
                                            Contains at least 1 alphabet
                                        </li>
                                        <li
                                            className={
                                                passwordCriteria.specialChar
                                                    ? "text-green-500"
                                                    : "text-red-600"
                                            }
                                        >
                                            Contains at least 1 special character
                                        </li>
                                    </ul>
                                    <button
                                        type="submit"
                                        className="w-full px-4 py-2 lg:py-3 mt-4 font-semibold text-white bg-secondary rounded-[10px] lg:rounded-[13px] uppercase"
                                    >
                                        {loader ? "loading..." : "reset password"}
                                    </button>
                                </>
                            )}
                            {nextSteptype === "Passwordcheck" && (
                                <>
                                    <div className="mb-3">
                                        {loginOption === "unique" && (
                                            <>
                                                <input
                                                    type="text"
                                                    placeholder="Enter Unique ID"
                                                    className="w-full px-4 py-2 lg:py-3 mobile:rounded-[40px] mobile:py-4 rounded-[10px] lg:rounded-[13px] placeholder-primary bg-transparent border-2 border-primary focus:outline-none focus:border-primary"
                                                    name="uniqueId"
                                                    onChange={formik.handleChange}
                                                    value={formik.values.uniqueId}
                                                    readOnly
                                                    disabled
                                                />
                                                {formik.touched.uniqueId && formik.errors.uniqueId && (
                                                    <div className="text-red-500 mt-2">
                                                        {formik.errors.uniqueId}
                                                    </div>
                                                )}
                                            </>
                                        )}
                                        {loginOption === "email" && (
                                            <>
                                                <input
                                                    type="email"
                                                    placeholder="Enter Email ID"
                                                    className="w-full px-4 py-2 lg:py-3 mobile:rounded-[40px] mobile:py-4 rounded-[10px] lg:rounded-[13px] placeholder-primary bg-transparent border-2 border-primary focus:outline-none focus:border-primary"
                                                    name="email"
                                                    onChange={formik.handleChange}
                                                    value={formik.values.email}
                                                    readOnly
                                                    disabled
                                                />
                                                {formik.touched.email && formik.errors.email && (
                                                    <div className="text-red-500 mt-2">
                                                        {formik.errors.email}
                                                    </div>
                                                )}
                                            </>
                                        )}
                                        {loginOption === "mobile" && (
                                            <>
                                                <input
                                                    type="text"
                                                    placeholder="Enter Mobile Number"
                                                    className="w-full px-4 py-2 mobile:rounded-[40px] mobile:py-4 lg:py-3 rounded-[10px] lg:rounded-[13px] placeholder-primary bg-transparent border-2 border-primary focus:outline-none focus:border-primary"
                                                    name="mobile"
                                                    onChange={formik.handleChange}
                                                    value={formik.values.mobile}
                                                    readOnly
                                                    disabled
                                                />
                                                {formik.touched.mobile && formik.errors.mobile && (
                                                    <div className="text-red-500 mt-2">
                                                        {formik.errors.mobile}
                                                    </div>
                                                )}
                                            </>
                                        )}
                                    </div>
                                    <div className="mb-3">
                                        <input
                                            type="password"
                                            placeholder="Enter Password"
                                            className="w-full px-4 py-2 lg:py-3 rounded-[10px] mobile:rounded-[40px] mobile:py-4 lg:rounded-[13px] placeholder-primary bg-transparent border-2 border-primary focus:outline-none focus:border-primary"
                                            name="password"
                                            onChange={formik.handleChange}
                                            value={formik.values.password}
                                        />
                                        {formik.touched.password && formik.errors.password && (
                                            <div className="text-red-500 mt-2">
                                                {formik.errors.password}
                                            </div>
                                        )}
                                    </div>
                                    <button
                                        type="submit"
                                        className="w-full px-4 py-2 lg:py-3 mt-4 font-semibold text-white bg-secondary rounded-[10px] mobile:rounded-[40px] mobile:py-4 lg:rounded-[13px] uppercase"
                                    >
                                        {loader ? "loading..." : "Login"}
                                    </button>
                                </>
                            )}
                        </form>
                    </div>
                    <div className="laptop:hidden">
                        <footer className="bg-white shadow-md   w-full font-poppins ">
                            <div className="container  mobile:font-m=normal mx-auto p-4 flex flex-col sm:flex-row justify-between items-center text-center sm:text-left">
                                {/* Left Section */}
                                <div className="text-[#214284]  text-sm mb-2 sm:mb-0">
                                    <p>
                                        <Link to="/" className="underline">
                                            Terms and Condition
                                        </Link>{" "}
                                        <br />{" "}
                                        <Link to="/" className="underline px-2">
                                            Privacy Policy
                                        </Link>{" "}
                                        |  {"  "}
                                        <Link to="/" className="underline px-2">
                                            Contact Us
                                        </Link>
                                    </p>
                                </div>

                                {/* Right Section */}
                                <div className="text-[#214284] text-[16px]">
                                    <p>&copy; 2021 All Rights Reserved by TFS</p>
                                </div>
                            </div>
                        </footer>
                    </div>
                </div>
            </div>

        </>
    );
};

export default Login;
