import React, { useRef, useState } from 'react'
import round from "./../../Images/round.svg"
import playImg from "../../Images/play.svg"
import { Link } from 'react-router-dom'
const GradientDiv = () => {
    const [isPlaying, setIsPlaying] = useState(false);
    const iframeRef = useRef(null);

    const handlePlayPause = () => {
        const message = isPlaying ? 'pauseVideo' : 'playVideo';
        iframeRef.current.contentWindow.postMessage(`{"event":"command","func":"${message}","args":""}`, '*');
        setIsPlaying(!isPlaying);
    };
    return (
        <div className='mx-28 mt-28 mobile:m-5 mobile:p-3 mobile:flex-col mobile:mt-7 flex flex-wrap items-center justify-between p-6 linerarBg  '>
            <div className='flex mobile:flex-col gap-5 w-full pr-32 mobile:p-2'>
                <div className='w-[40%] mobile:w-full mobile:h-full'>
                    <div className='rounded-[40px] bg-[#FFF] mobile:h-[200px] mobile:object-cover w-full h-full flex justify-center items-center relative'>
                        <iframe
                            ref={iframeRef}
                            width="100%"
                            className='rounded-[40px]'
                            height="100%"
                            src="https://www.youtube.com/embed/VvdgQNzSXSA?enablejsapi=1"
                            title="YouTube video player"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                        ></iframe>
                        <img
                            src={playImg}
                            alt='Play'
                            className={`mobile:w-[25%] mobile:my-16 absolute cursor-pointer ${!isPlaying && "hidden"}`}
                            onClick={handlePlayPause}
                        />
                    </div>
                </div>
                <div className='w-[60%] mobile:w-full mobile:mt-3 flex flex-col gap-4 mt-7 '>
                    <p className="text-[18px] font-[400] text-primary font-poppins">ENROLL IN OUR PROGRAM</p>
                    {/* 3 */}
                    <p className='text-[36px] font-[700] font-Lora text-primary leading-[46.08px]  font-lora mobile:text-xl'>TIC 6.0 Live Mentorship Program
                        By Nishant Arora </p>
                    <p className="text-[18px] font-[500] text-primary font-poppins mobile:hidden">Registration Date : 1st MAY, 2024    | Start Date : 20th MAY, 2024  </p>
                    <p className="text-[16px] font-[500] text-primary font-poppins">Registration Date : 1st MAY, 2024  <br />Start Date : 20th MAY, 2024  </p>
                    {/* 3.1 */}
                    <hr className='w-[15%] border-[3px] border-secondary  mobile:w-[35%]' />
                    <p className="text-[18px] font-[500] text-primary font-poppins">What We Provide</p>
                    <div className='grid grid-cols-3 mobile:grid-cols-1 gap-5 w-full justify-center items-center text-center'>
                        <div className='bg-[#FFF] mobile:flex mobile:gap-5 rounded-[15px] px-9 py-4 flex flex-col mobile:flex-row items-center gap-3 justify-center'>
                            <img src='https://tfspublic.s3.ap-south-1.amazonaws.com/images/Home+Page/educational_guidance.png' className='mobile:w-[60px] mobile:h-[60px] w-[30px]' />
                            <p className='text-primary text-[18px] font-[500] font-poppins'>Educational
                                Guidance</p>
                        </div>
                        <div className='bg-[#FFF] rounded-[15px] mobile:flex-row  mobile:flex mobile:gap-5 px-9 py-4 flex flex-col items-center gap-3 justify-center'>
                            <img src='https://tfspublic.s3.ap-south-1.amazonaws.com/images/Home+Page/tic_private_repository.png' className='mobile:w-[60px] mobile:h-[60px] w-[30px]' />
                            <p className='text-primary text-[18px] font-[500] font-poppins'>TIC Private Repository </p>
                        </div>
                        <div className='bg-[#FFF] rounded-[15px] px-9 py-4 mobile:flex mobile:gap-5 mobile:flex-row flex flex-col items-center gap-3 justify-center'>
                            <img src='https://tfspublic.s3.ap-south-1.amazonaws.com/images/Home+Page/webinar.png' className='mobile:w-[60px] mobile:h-[60px] w-[30px]' />
                            <p className='text-primary text-[18px] font-[500] font-poppins'>Webinar & Worksop's</p>
                        </div>
                    </div>
                    <div className='w-full flex mobile:mb-1 mb-5'>
                        <div className='flex flex-wrap mobile:w-full  laptop:w-full items-center px-7'>
                            {/* 4 */}
                            <Link to={"/getstarted"} className=' py-4  mobile:py-4  mobile:px-3 mobile:w-[100%] mt-4 mr-4 text-primary flex justify-center  font-[600] text-[20px] font-poppins getInTouch px-5'> Get Started Today</Link>
                            <Link to={"/contactus"} className=" py-3 mobile:w-full mt-4 text-primary flex justify-center items-center font-[600] text-[20px] font-poppins px-5 border-[3px] border-primary rounded-[35px]">Contact Us</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default GradientDiv
