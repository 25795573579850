import React, { useState, useRef, useEffect } from "react";

const FileUpload = ({ onUpload, uploadCount, setUploadCount, fileType }) => {

  // ---------------------------------------------- All variables ------------------------------------------

  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState("");
  const [fileSize, setFileSize] = useState("");
  const fileInputRef = useRef();

  // ---------------------------------------------- function for handle click ------------------------------------------

  const handleClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };
  // Function to convert Base64 to File object
  // const base64ToFile = (base64String, fileName) => {
  //   const [header, data] = base64String.split(',');
  //   const mimeString = header.split(':')[1].split(';')[0];
  //   const byteString = atob(data);
  //   const arrayBuffer = new ArrayBuffer(byteString.length);
  //   const uint8Array = new Uint8Array(arrayBuffer);

  //   for (let i = 0; i < byteString.length; i++) {
  //     uint8Array[i] = byteString.charCodeAt(i);
  //   }

  //   return new File([uint8Array], fileName, { type: mimeString });
  // };


  // Load saved file data from localStorage based on fileType
  // useEffect(() => {
  //   const savedData = localStorage.getItem('kycFormData');
  //   if (savedData) {
  //     const parsedData = JSON.parse(savedData);
  //     const fileType = 'profilephoto'; // Update this based on your requirement

  //     if (fileType === 'profilephoto' && parsedData.profilePhoto) {
  //       const base64String = parsedData.profilePhoto;
  //       const fileName = "profilephoto.png"; // Use a default name if not provided
  //       const file = base64ToFile(base64String, fileName);
  //       const fileUrl = URL.createObjectURL(file);

  //       setFile(fileUrl);
  //       setFileName(fileName);
  //       setFileSize((file.size / (1024 * 1024)).toFixed(2) + " MB");
  //     }
  //     if (fileType === 'idproof' && Array.isArray(parsedData.idProofs)) {
  //       const lastIdProof = parsedData.idProofs[parsedData.idProofs.length - 1];
  //       if (lastIdProof && lastIdProof.fileUrl) {
  //         const base64String = lastIdProof.fileUrl;
  //         const fileName = lastIdProof.fileName || "idproof.png";
  //         const file = base64ToFile(base64String, fileName);
  //         const fileUrl = URL.createObjectURL(file);

  //         setFile(fileUrl);
  //         setFileName(fileName);
  //         setFileSize((file.size / (1024 * 1024)).toFixed(2) + " MB");
  //       }
  //     }
  //   }
  // }, []);


  // ---------------------------------------------- function for handle upload file ------------------------------------------

  const handleUploadFile = (uploadedFile) => {
    if (uploadedFile) {
      try {
        const fileUrl = URL.createObjectURL(uploadedFile);
        setFile(fileUrl);
        setFileName(uploadedFile.name);
        setFileSize((uploadedFile.size / (1024 * 1024)).toFixed(2) + " MB");
        if (onUpload) onUpload(uploadedFile); // Call the onUpload callback
      } catch (error) {
        console.error("Failed to create object URL:", error);
      }
    }
  };

  // -------------------------------------------- function to handle file change --------------------------------------------

  const handleFileChange = (event) => {
    const uploadedFile = event.target.files[0];
    handleUploadFile(uploadedFile);
  };

  // --------------------------------------------- function to handle Remove filee --------------------------------------------

  const handleRemoveFile = () => {
    setFile(null);
    setFileName("");
    setFileSize("");
    if (fileInputRef.current) {
      fileInputRef.current.value = null;
    }
    if (uploadCount > 1) {
      setUploadCount(prevCount => prevCount - 1);
    }
  };

  return (
    <div className="w-full flex flex-col items-center sm:w-2/3">
      <div className={`flex flex-col sm:flex-row w-full mt-5 ${file ? 'bg-tertiary' : 'bg-white'} mobile:rounded-[30px] text-[#595f6d] rounded-[723px] border border-dashed border-[#214284] font-poppins p-5`}>
        {!file ? (
          <div className="flex flex-col sm:flex-row items-center justify-between w-full font-poppins h-13">
            <div className="flex flex-col sm:flex-row items-center justify-between w-full mb-4 sm:mb-0">
              <div className="flex items-center mb-4 sm:mb-0">
                <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M32 32L24 24L16 32" stroke="#214284" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M24 24V42" stroke="#214284" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                  <path
                    d="M40.7799 36.78C42.7306 35.7165 44.2716 34.0337 45.1597 31.9972C46.0477 29.9607 46.2323 27.6864 45.6843 25.5334C45.1363 23.3803 43.8869 21.471 42.1333 20.1069C40.3796 18.7427 38.2216 18.0014 35.9999 18H33.4799C32.8745 15.6585 31.7462 13.4846 30.1798 11.642C28.6134 9.79927 26.6496 8.33567 24.4361 7.36118C22.2226 6.3867 19.817 5.92669 17.4002 6.01573C14.9833 6.10478 12.6181 6.74057 10.4823 7.8753C8.34649 9.01003 6.49574 10.6142 5.06916 12.5671C3.64259 14.5201 2.6773 16.771 2.24588 19.1508C1.81446 21.5305 1.92813 23.977 2.57835 26.3065C3.22856 28.636 4.3984 30.7877 5.99992 32.6"
                    stroke="#214284"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path d="M32 32L24 24L16 32" stroke="#214284" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
                <span className="ml-3 text-sm sm:text-base text-primary">
                  Select a file or drag and drop here
                  <br />
                  JPG, PNG or PDF, file size no more than 10MB
                </span>
              </div>
              <div className="border border-[#214284] text-center rounded-[36px] p-2 w-full sm:w-auto">
                <button
                  type="button"
                  onClick={handleClick}
                  className="font-light text-center text-[#214284] w-full sm:w-auto"
                >
                  SELECT FILE
                </button>
              </div>
            </div>
          </div>
        ) : (
          <div className="flex flex-row moble:items-center mobile:rounded-[63px] sm:flex-row items-center justify-between w-full">
            <div className="flex items-center">
              <svg
                width="18"
                height="24"
                viewBox="0 0 18 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.75293 10.5C7.0484 10.5 7.34099 10.4418 7.61397 10.3287C7.88695 10.2157 8.13499 10.0499 8.34392 9.84099C8.55285 9.63206 8.71859 9.38402 8.83166 9.11104C8.94473 8.83806 9.00293 8.54547 9.00293 8.25C9.00293 7.95453 8.94473 7.66194 8.83166 7.38896C8.71859 7.11598 8.55285 6.86794 8.34392 6.65901C8.13499 6.45008 7.88695 6.28434 7.61397 6.17127C7.34099 6.0582 7.0484 6 6.75293 6C6.15619 6 5.5839 6.23705 5.16194 6.65901C4.73998 7.08097 4.50293 7.65326 4.50293 8.25C4.50293 8.84674 4.73998 9.41903 5.16194 9.84099C5.5839 10.2629 6.15619 10.5 6.75293 10.5Z"
                  fill="#FFBB6B"
                />
                <path
                  d="M18 21C18 21.7956 17.6839 22.5587 17.1213 23.1213C16.5587 23.6839 15.7956 24 15 24H3C2.20435 24 1.44129 23.6839 0.87868 23.1213C0.31607 22.5587 0 21.7956 0 21V3C0 2.20435 0.31607 1.44129 0.87868 0.87868C1.44129 0.316071 2.20435 0 3 0L11.25 0L18 6.75V21ZM3 1.5C2.60218 1.5 2.22064 1.65804 1.93934 1.93934C1.65804 2.22064 1.5 2.60218 1.5 3V18L4.836 14.664C4.95422 14.5461 5.10843 14.4709 5.27417 14.4506C5.43992 14.4302 5.60773 14.4657 5.751 14.5515L9 16.5L12.2355 11.97C12.2988 11.8815 12.3806 11.8078 12.4753 11.754C12.5699 11.7003 12.6751 11.6678 12.7836 11.6588C12.8921 11.6498 13.0012 11.6645 13.1034 11.702C13.2056 11.7394 13.2985 11.7986 13.3755 11.8755L16.5 15V6.75H13.5C12.9033 6.75 12.331 6.51295 11.909 6.09099C11.4871 5.66903 11.25 5.09674 11.25 4.5V1.5H3Z"
                  fill="#FFBB6B"
                />
              </svg>
              <span className="text-sm font-normal ml-3 text-primary">
                {fileName}
              </span>
              <a
                href={file}
                target="_blank"
                rel="noopener noreferrer"
                className="ml-4 text-secondary"
              >
                Preview
              </a>
            </div>
            <div className=" justify-center flex items-center mt-4 sm:mt-0">
              <span className="text-sm text-primary mr-4">{fileSize}</span>
              <button type="button" onClick={handleRemoveFile} className="ml-4">
                <svg
                  width="25"
                  height="24"
                  viewBox="0 0 25 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12.25 0C5.62258 0 0.25 5.37258 0.25 12C0.25 18.6275 5.62258 24 12.25 24C18.8775 24 24.25 18.6275 24.25 12C24.25 5.37258 18.8775 0 12.25 0ZM9.68439 8.25069C9.35744 7.92374 8.82735 7.92374 8.5004 8.25069C8.17345 8.57765 8.17345 9.10773 8.5004 9.43468L11.0657 12L8.5004 14.5653C8.17345 14.8923 8.17345 15.4224 8.5004 15.7494C8.82735 16.0763 9.35744 16.0763 9.68439 15.7494L12.2497 13.184L14.815 15.7494C15.1419 16.0763 15.6721 16.0763 15.999 15.7494C16.326 15.4224 16.326 14.8923 15.999 14.5653L13.4337 12L15.999 9.43468C16.326 9.10773 16.326 8.57765 15.999 8.25069C15.6721 7.92374 15.1419 7.92374 14.815 8.25069L12.2497 10.816L9.68439 8.25069Z"
                    fill="#FFBB6B"
                  />
                </svg>
              </button>
            </div>
          </div>
        )}
        <input
          type="file"
          accept="image/jpeg,image/png,application/pdf"
          className="hidden"
          ref={fileInputRef}
          onChange={handleFileChange}
        />
      </div>
    </div>
  );
};

export default FileUpload;
