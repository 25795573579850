import React from 'react';
import { Link } from 'react-router-dom';

const HeroSection = () => {
    return (
        <div className='w-full '>
            <div className='max-w-screen-xl  mb-0 flex mobile:flex-col flex-wrap items-center justify-between mx-auto p-4 mobile:p-0 pb-0'>
                <div className='w-[65%] z-10 mt-28 mobile:w-full mobile:p-5'>
                    <p className='text-h1 mobile:text-4xl mt-5 font-lora mobile:leading-10 pr-7 font-[700] text-primary'>
                        Transform Your Trading Journey: <span className='text-secondary '>
                            Join the Trusted
                            TFS Success Academy!</span>
                    </p>
                    <hr className='w-[15%] mt-5 border-[2px] border-[#FFBB6B] mobile:hidden' />
                    <p className='text-body font-poppins w-[75%] mobile:w-full mobile:text-justify font-normal text-primary mobile:mt-5 mt-10  ' >
                        Discover a holistic approach to trading education at Train For
                        Success (TFS), where our renowned founder Nishant Arora leads
                        a community of over 20,000 members towards financial mastery and personal development.
                    </p>
                    <div className='flex flex-wrap items-center gap-5 mt-10 mobile:mt-7 mobile:w-full'>
                        <Link to={"/getstarted"} className='mobile:w-full'>
                            <p className=" py-4 mobile:w-full text-primary flex justify-center items-center font-[600] text-[20px] font-poppins getInTouch px-10 ">
                                Get Started Today
                            </p>
                        </Link>
                        <a href="https://www.youtube.com/watch?v=Mga-RKTXpYA" target="_blank" rel="noopener noreferrer" className="py-3 mobile:w-full text-primary flex justify-center items-center font-[600] text-[20px] font-poppins px-10 border-[3px] border-primary rounded-[35px]">
                            Watch Video
                        </a>
                    </div>
                </div>
                <div className='w-[35%] mobile:w-full mobile:mt-5 mt-28'>
                    <div className='w-[550px] h-[605px] mobile:p-0 mobile:w-full mobile:ml-0 flex justify-center items-center bg-[#D9D9D9] -ml-40'>
                        <iframe
                            width="100%"
                            height="100%"
                            src="https://www.youtube.com/embed/Mga-RKTXpYA?autoplay=1&mute=1"
                            title="YouTube video player"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen>
                        </iframe>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HeroSection;
