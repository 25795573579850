import { ErrorMessage, Field, Form, Formik } from 'formik';
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { registerToEvent, updateStatus } from '../../store/actions/website.action';
import { batchCancel, batchChangeReq, batchChangeReqList, getAllBatches } from '../../store/actions/auth.action';
import * as Yup from 'yup';

const BatchChange = ({ profileData, openTab }) => {
  const dispatch = useDispatch();
  const { loader, batchListres, batchreqlist } = useSelector((state) => state?.auth);
  const batchValidationSchema = Yup.object().shape({
    newBatchno: Yup.string().required('New Batch number is required')
  });

  const batchCancelReq = (row) => {
    const payload = {
      "action": "CANCEL"
    }
    dispatch(batchCancel(payload, (response) => {
      if (response?.status == 200) {
        const payload = {
          requestId: "",
          requestStatus: "",
          pageNo: 1,
          perPageResults: 10,
        };
        dispatch(batchChangeReqList(payload));
      }
    }))
  }

  const openPayModal = (response, id) => {
    // const paymentId = sessionStorage.getItem("orderid");
    // console.log("payment Id---", id)
    const options = {
      key: "rzp_live_POZ84Rf3NXSoEH",
      amount: response.data.amount,
      order_id: id,
      name: "TFS",
      handler: function (response) {
        // const paymentId = sessionStorage.getItem("orderid");
        dispatch(updateStatus(id, (res) => {
          if (res.status === 200) {
            console.log("Payment successful!");
          }
        }));
      },
      'modal': {
        'ondismiss': function (res) {
          dispatch(updateStatus(id, (res) => {
            if (res.status === 200) {
              console.log("Payment successful!");
            }
          }));
        }
      }
      ,
      theme: {
        color: "#214284",
        hide_topbar: true,
      },
    };
    const rzp1 = new window.Razorpay(options);
    rzp1.open();
  };

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const updateOrderIdInSession = (id) => {
    sessionStorage.setItem("orderid", id);
    console.log("id---", id)
  };

  const handleBatchChange = (id) => {
    const payload = {
      action: "BATCH_CHANGE"
      // actionId: id,
    };
    dispatch(registerToEvent(payload, (response) => {
      if (response?.status === 200) {

        openPayModal(response, response?.data?.id);
        updateOrderIdInSession(response?.data?.id);
        const payload = {
          requestId: "",
          requestStatus: "",
          pageNo: 1,
          perPageResults: 10,
        };
        dispatch(batchChangeReqList(payload));
      } else if (response?.response?.status === 400) {
        const existingOrderId = sessionStorage.getItem("orderid");
        if (existingOrderId) {
          dispatch(updateStatus(existingOrderId, (updateResponse) => {
            if (updateResponse?.status === 200) {
              handleBatchChange();
              const payload = {
                requestId: "",
                requestStatus: "",
                pageNo: 1,
                perPageResults: 10,
              };
              dispatch(batchChangeReqList(payload));
            }
          }));
        }
      }
    }));
  };
  useEffect(() => {
    const payload = {
      requestId: "",
      requestStatus: "",
      pageNo: 1,
      perPageResults: 10,
    };
    dispatch(getAllBatches(payload));
    dispatch(batchChangeReqList(payload));
  }, [dispatch, openTab]);
  return (
    <>
      <Formik
        initialValues={{
          batchno: profileData?.batchNo || '',
          newBatchno: ''
        }}
        validationSchema={batchValidationSchema}
        onSubmit={(values) => {
          const payload = {
            "batchNo": values.newBatchno,
            "action": "SUBMIT"
          };
          dispatch(batchChangeReq(payload, (response) => {
            if (response?.status) {
              const payload = {
                requestId: "",
                requestStatus: "",
                pageNo: 1,
                perPageResults: 10,
              };
              dispatch(batchChangeReqList(payload));
            }
          }));
          console.log('Batch form values:', values);
        }}
        enableReinitialize
      >
        {({ isValid, dirty, setFieldValue }) => (
          <Form className="w-full bg-white rounded-[40px] h-full mobile:p-3">
            <div className="p-10 w-full mobile:p-3">
              <div className="flex justify-center mobile:flex-col gap-7 mt-5 w-full">
                <div className="mb-5 w-[40%] mobile:w-full">
                  <label htmlFor="batchno" className="text-base font-normal font-poppins block mb-2 text-primary">
                    Current Batch No
                  </label>
                  <Field
                    type="text"
                    id="batchno"
                    name="batchno"
                    className="border border-primary text-[#21428486] bg-[#2142841a] text-base font-semibold font-poppins rounded-[39px] focus:ring-0 focus:border-primary block w-full p-2.5 placeholder:text-primary"
                    readOnly
                  />
                </div>
                <div className="mb-5 w-[40%] mobile:w-full">
                  <label htmlFor="newBatchno" className="text-base font-normal font-poppins block mb-2 text-primary">
                    Select New Batch <span className='text-secondary'>*</span>
                  </label>
                  <Field
                    as="select"
                    id="newBatchno"
                    name="newBatchno"
                    className="border border-primary text-primary text-base font-semibold font-poppins rounded-[39px] focus:ring-0 focus:border-primary block w-full p-2.5 placeholder:text-primary"
                    onChange={(e) => setFieldValue('newBatchno', e.target.value)}
                  >
                    <option value="" hidden>Select New Batch</option>
                    {batchListres?.batchList?.map((batch) => (
                      <option key={batch?.id} value={batch?.id}>{batch?.id}</option>
                    ))}
                  </Field>
                  <ErrorMessage name="newBatchno" component="div" className="text-red-500" />
                </div>
              </div>
              <div className="flex justify-end mobile:justify-center mobile:w-full mt-5">
                <button
                  type="submit"
                  className={`text-white bg-primary rounded-[39px]   text-lg px-10 py-2 ${!isValid || !dirty ? 'opacity-50 cursor-not-allowed' : 'opacity-100'}`}
                  disabled={!isValid || !dirty}
                >
                  SEND REQUEST
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
      <div className="w-full mt-10 overflow-auto">
        <table className="min-w-full">
          <thead className="bg-white">
            <tr>
              <th className="px-6 py-3 text-lg text-center text-primary font-bold">Sr. No.</th>
              <th className="px-6 py-3 text-lg text-center text-primary font-bold">User Name</th>
              <th className="px-6 py-3 text-lg text-center text-primary font-bold">Current Batch No</th>
              <th className="px-6 py-3 text-lg text-center text-primary font-bold">Requested Batch No</th>
              <th className="px-6 py-3 text-lg text-center text-primary font-bold">Status</th>
              <th className="px-6 py-3 text-lg text-center text-primary font-bold">Action</th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200">

            {batchreqlist?.userList?.map((row, i) => (
              <tr className="border-b font-poppins text-primary border-b-[#21248427]" key={i}>
                <td className="px-6 py-4 text-lg text-center">{i + 1}</td>
                <td className="px-6 py-4 text-lg text-center">{row?.userName}</td>
                <td className="px-6 py-4 text-lg text-center">{row?.currentBatchNo}</td>
                <td className="px-6 py-4 text-lg text-center">{row?.batchNo}</td>
                <td className="px-6 py-4 text-lg text-center">{row?.requestStatus}</td>
                <td className="px-6 py-4 text-lg text-center flex justify-center items-center">
                  {row?.requestStatus === "SUBMITTED" && (
                    <div className="cursor-pointer" onClick={() => batchCancelReq(row)}>
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M12 1.25C6.06294 1.25 1.25 6.06294 1.25 12C1.25 17.9371 6.06294 22.75 12 22.75C17.9371 22.75 22.75 17.9371 22.75 12C22.75 6.06294 17.9371 1.25 12 1.25ZM9.70164 8.64124C9.40875 8.34835 8.93388 8.34835 8.64098 8.64124C8.34809 8.93414 8.34809 9.40901 8.64098 9.7019L10.9391 12L8.64098 14.2981C8.34809 14.591 8.34809 15.0659 8.64098 15.3588C8.93388 15.6517 9.40875 15.6517 9.70164 15.3588L11.9997 13.0607L14.2978 15.3588C14.5907 15.6517 15.0656 15.6517 15.3585 15.3588C15.6514 15.0659 15.6514 14.591 15.3585 14.2981L13.0604 12L15.3585 9.7019C15.6514 9.40901 15.6514 8.93414 15.3585 8.64124C15.0656 8.34835 14.5907 8.34835 14.2978 8.64124L11.9997 10.9393L9.70164 8.64124Z" fill="#FFBB6B" />
                      </svg>
                    </div>
                  )}
                  {(row?.requestStatus === "REJECTED" || row?.requestStatus === "CANCELLED" || row?.requestStatus === "APPROVED") && ""}
                  {row?.requestStatus === "PENDING" && <button className='bg-green-700 text-white rounded-[13px]
                                 py-2 px-3' onClick={() => handleBatchChange(row?.requestId)}>Pay Now</button>}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  )
}

export default BatchChange
