import React from "react";
import { Link } from "react-router-dom";
import logo from "../Images/logo.svg";
import liImg from "../Images/tap-to-pay 1.svg";


const KycComplete = () => {
  return (
    <div className="w-full  min-h-screen bg-[#FFFAED] relative">


      <div className="min-h-screen flex flex-col items-center  bg-tertiary ">
        <div className="bg-white p-8  rounded-[40px] shadow-md mt-5  mobile:rounded-none w-full max-w-4xl">
          <div className="flex justify-center items-center ">
            <img src={liImg} className="text-center" alt="" />
          </div>
          <h2 className="font-lora font-bold leading-14 text-center mb-7  mt-7 text-4xl text-[#214284]">
            Thank You for <br />Completing KYC!
          </h2>
          <hr className="border-t-2 opacity-16 mb-4" />

          <div
            htmlFor="default-checkbox"
            className=" text-sm mb-3 mt-6 font-light font-poppins text-center text-primary dark:text-gray-300"
          >
            We have sent you a confirmation email, which you should receive within 12-24 hours.<br /> Please check your inbox or spam folder for an email containing your unique ID and password to log in to the portal.
          </div>

        </div>
      </div>

    </div>
  );
};

export default KycComplete;
