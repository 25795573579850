import React from "react";
import { Link } from "react-router-dom";
import logo from "../Images/logo.svg";
import StepIndicator from "./StepIndicator";
import Button from "./Button";
import liImg from "../Images/tap-to-pay 1.svg";

const KycCompleteToDashboard = () => {
  return (
    <div className="w-full min-h-screen bg-[#FFFAED] relative">


      <div className="min-h-screen flex flex-col items-center bg-tertiary ">
        <div className="bg-white p-8 rounded-[40px]  mobile:rounded-none mt-4 shadow-md w-full max-w-lg">
          <div className="flex justify-center items-center mt-7">
            <img src={liImg} className="text-center" alt="" />
          </div>
          <h2 className="font-lora font-bold leading-14 text-center mb-7  mt-7 text-4xl text-[#214284]">
            Thank You for <br />
            Completing KYC!
          </h2>
          <hr className="border-t-2 opacity-16 mb-4" />

          <div className="   mt-6  font-poppins text-center border-[5px]  border-primary rounded-[50px] font-semibold text-primary ">
            <button
              className="text-[20px]  text-center  leading-[20px] text-primary  md:w-6/12 h-[50px] rounded-[36px] opacity-90 hover:opacity-100 transition-opacity"

            >
              Go To Dashboard
            </button>

          </div>
        </div>
      </div>

    </div>
  );
};

export default KycCompleteToDashboard;
