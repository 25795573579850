import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import logo from "../Images/logo.svg";
import liImg from "../Images/tap-to-pay 1.svg";
import { useDispatch } from "react-redux";
import { enterBasicDetails } from "../store/actions/admin.action";
import toast from "react-hot-toast";
import Button from "./Button";

const CodeSchema = Yup.object().shape({
  code1: Yup.string().required("Required").matches(/^[0-9]$/, "Must be a digit"),
  code2: Yup.string().required("Required").matches(/^[0-9]$/, "Must be a digit"),
  code3: Yup.string().required("Required").matches(/^[0-9]$/, "Must be a digit"),
  code4: Yup.string().required("Required").matches(/^[0-9]$/, "Must be a digit"),
  code5: Yup.string().required("Required").matches(/^[0-9]$/, "Must be a digit"),
  code6: Yup.string().required("Required").matches(/^[0-9]$/, "Must be a digit"),
});

const Confirmation = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const emailid = localStorage.getItem("newdata");


  const handleAuthenticate = (values) => {
    // Retrieve form data and response data from local storage
    const formData = JSON.parse(localStorage.getItem("formData"));
    const responseData = JSON.parse(localStorage.getItem("responseData"));

    if (formData && responseData) {
      const payload = {
        emailId: formData.email,
        mobileNo: formData.mobileNo,
        firstName: formData.firstName,
        lastName: formData.lastName,
        otpReference: responseData.referenceNo,
        otp: Object.values(values).join(""),
        grantType: "userOnboardingStep2",
      };

      dispatch(enterBasicDetails(payload, (response) => {
        if (response?.status === 200) {
          toast?.success(response?.data?.message);
          localStorage.setItem("paymentdata", JSON.stringify(response?.data));
          navigate("/kyc-payment");
        }
      }));
    } else {
      toast.error("Failed to retrieve stored data. Please try again.");
    }
  };

  const handleChange = (e, setFieldValue, index) => {
    const { value } = e.target;
    if (/^[0-9]$/.test(value)) {
      setFieldValue(`code${index}`, value);
      if (index < 6) {
        document.getElementById(`code-input-${index + 1}`).focus();
      }
    } else if (value === "") {
      setFieldValue(`code${index}`, "");
    }
  };

  const handleKeyDown = (e, setFieldValue, index) => {
    if (e.key === "Backspace" && e.target.value === "") {
      setFieldValue(`code${index}`, "");
      if (index > 1) {
        document.getElementById(`code-input-${index - 1}`).focus();
      }
    }
  };

  const handleResend = () => {
    const values = JSON.parse(localStorage.getItem("basicdet"));
    console.log(values);
    const payload = {
      "emailId": values?.email,
      "mobileNo": values?.mobileNo,
      "firstName": values?.firstName,
      "lastName": values?.lastName,
      "grantType": "userOnboardingStep1"
    };

    // Store form values in localStorage
    // localStorage.setItem("formData", JSON.stringify(values));

    dispatch(enterBasicDetails(payload, (response) => {
      if (response?.status === 200) {
        // // Store response data in localStorage
        localStorage.setItem("responseData", JSON.stringify(response?.data));
        // localStorage.setItem("newdata", values?.email);
        // localStorage.setItem("basicdet", JSON.stringify(values));
        toast?.success(response?.data?.message);
        // navigate("/confirmation");
      }
    }))
  }

  return (
    <div className="w-full min-h-screen bg-[#FFFAED] flex justify-center items-center">
      <div className="bg-white shadow-md w-full max-w-md  rounded-lg mt-6 mb-6">
        <div className="flex justify-center mb-4">
          <img src={liImg} className="w-20" alt="Tap to pay" />
        </div>
        <h2 className="font-lora font-[800] p-4 text-center text-5xl text-[#214284] mb-4">
          Thank You For Choosing Us!
        </h2>
        <p className="text-center text-[#2142847e] p-4 font-poppins text-l mb-8">
          Please confirm your account by entering the authorization code sent to your email address:<span className="font-bold"> {emailid}</span>
        </p>
        <Formik
          initialValues={{ code1: "", code2: "", code3: "", code4: "", code5: "", code6: "" }}
          validationSchema={CodeSchema}
          onSubmit={handleAuthenticate}
        >
          {({ isValid, dirty, setFieldValue }) => (
            <Form>
              <div className="flex justify-center mb-6 gap-1">
                {[1, 2, 3, 4, 5, 6].map((num) => (
                  <Field
                    key={num}
                    name={`code${num}`}
                    maxLength="1"
                    placeholder="-"
                    className="w-12 h-12 border border-gray-300 text-primary bg-[#FFFAED] text-center text-xl rounded-md focus:outline-none"
                    style={{ border: "1px solid rgba(33, 66, 132, 0.3)", borderRadius: "6px" }}
                    onChange={(e) => handleChange(e, setFieldValue, num)}
                    onKeyDown={(e) => handleKeyDown(e, setFieldValue, num)}
                    id={`code-input-${num}`}
                    autoComplete="off"
                  />
                ))}
              </div>
              <div className="text-center mb-4 mobile:p-4">
                <p className="text-[#2142849c] font-poppins">It may take a minute to receive your code.</p>
                <p className="text-[#214284a9] font-poppins mt-3">
                  Haven’t Received Your Code yet?{" "}
                  <button
                    type="button"
                    onClick={handleResend}
                    className="text-secondary font-bold font-rubik"
                  >
                    Resend new code
                  </button>
                </p>
              </div>


              <div className="border-t-2 opacity-16 mb-3"></div>
              <div className="flex flex-row p-4 justify-center lg:justify-between items-center gap-4">
                <Link
                  to="/previous-page" // replace with actual route
                  className={`${isValid && dirty ? "border-[#214284]  text-[#005C87]" : "border-[#21428457] text-[#005c878a]"} font-lora text-[16px] border shadow-sm py-2 px-4 font-bold text-center w-[150px] h-[60px] flex items-center justify-center mobile:rounded-[40px]`}
                >
                  Go Back
                </Link>
                <button
                  type="submit"
                  className={`font-lora text-[16px]  h-[60px] mobile:rounded-[40px] font-bold text-white w-[280px]  ${isValid && dirty ? "bg-[#214284]" : "bg-[#21428457]"}`}
                  disabled={!isValid || !dirty}
                >
                  Authenticate Code
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default Confirmation;
