import React from 'react'

const ResetPwdModal = ({ setresetpassword, resetPassword, handleResetPwd, setaccResetpwd }) => {
    return (
        <>
            <div
                className="overflow-y-auto overflow-x-hidden fixed z-[999] bg-black bg-opacity-[0.6] justify-center h-full flex items-center w-full inset-0 max-h-full font-raleway"
                onClick={(e) => {
                    e.currentTarget === e.target && setresetpassword(false);
                }}
            >
                <div className="justify-center items-center gap-5 flex  flex-col p-5 w-[30%] tablet:w-[90%] mobile:w-[90%] text-center max-h-full bg-white opacity-100 inset-5" >
                    <h4 >
                        Your account is locked due to multiple wrong password activity. Please reset password.
                    </h4>
                    <button type='submit' className='bg-primary text-white px-5 py-2 cursor-pointer ' onClick={handleResetPwd}>Ok</button>
                </div>
            </div>
        </>
    )
}

export default ResetPwdModal
