import React, { useEffect, useState } from 'react'
import Slider from 'react-slick'
import Sliders from "../../Images/slider.png"
import { allgallery } from '../../store/actions/website.action';
import { useDispatch, useSelector } from 'react-redux';

const Carousel = () => {
    const { galaryData, loader } = useSelector((state) => state?.web);
    console.log("galaryData=====>>", galaryData);
    const [openTab, setOpenTab] = useState(1);
    const dispatch = useDispatch();
    useEffect(() => {

        const payload = {
            "title": "",
            "type": "video", //image,video
            // "createdDateFrom": "19-01-2024",
            // "createdDateTo": "21-01-2024",
            "pageNo": 0,
            "perPageResults": 0
        }
        dispatch(allgallery(payload))

    }, [dispatch, openTab])
    const settings = {
        autoplay: true,
        slidesToScroll: 1,
        centerMode: true,
        infinite: true,
        slidesToShow: 3.6,
        speed: 500,
        responsive: [
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1,
                    centerMode: false, // disable centerMode for better spacing control
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    padding: 10,
                    slidesToScroll: 1,
                    initialSlide: 1,
                    centerMode: false, // disable centerMode for better spacing control
                }
            }
        ]
    }
    const podcastBaseUrl = 'https://tfspublic.s3.ap-south-1.amazonaws.com/galleryAttachments/videos/';
    const filteredGalleryList = galaryData?.galleryList?.filter(item =>
        item.attachments.some(attachment => attachment.startsWith(podcastBaseUrl))
    );

    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 21;

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredGalleryList?.slice(indexOfFirstItem, indexOfLastItem);


    return (
        <div className='overflow-hidden'>
            <div className="slider-container mt-10 mobile:mt-0">
                <Slider {...settings}>
                    {currentItems?.map((item) => (
                        <div className='relative ml-6 mobile:ml-6 ' key={item?.galleryId}>
                            <div>
                                <video src={item?.attachments?.[0] || Sliders} alt='' className='h-[400px] rounded-t-[60px] rounded-b-[60px] w-[350px] object-cover' />
                                <div className="absolute w-[88.6%] mobile:w-[90%]  rounded-b-[60px] inset-x-0 bottom-0 h-[30%] bg-[#214284] opacity-[60%]"></div>
                                <div className="absolute w-[65%] inset-x-0 pl-7 bottom-0 text-white text-start pb-3">
                                    <p className='text-[18px] font-[600] font-poppins'>{item.title} | <br />
                                        TFS Talks | {item?.createdBy}</p>
                                    <p className='text-[16px] font-[400] font-poppins'> {item.createdDate}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </Slider>
            </div>
        </div>
    )
}

export default Carousel
