import React from "react";
import photoBg from "../../Images/photoBg.svg";
import userImg from "../../Images/user.png";
import { Link } from "react-router-dom";

const About = () => {
    return (
        <div className="bg-primary flex mobile:flex-col-reverse mobile:h-full mobile:p-4 p-24 mobile:-mt-9 mobile:z-30 relative mobile:rounded-t-[40px] h-[650px] rounded-t-[90px]">

            {/* 1 */}
            <div className="w-[50%] mobile:w-full mb-0 flex justify-end items-baseline relative">
                <img src={photoBg} alt="" className="mt-56 mobile:mt-32  mobile:p-0 absolute mobile:linebg" />
                <img src='https://tfspublic.s3.ap-south-1.amazonaws.com/images/Home+Page/Group+1321314670.png' alt="user" className="-mt-20 mobile:w-[410px] w-fit h-[650px] mobile:-mt-20 mobile:p-0" />
            </div>

            <div className="w-[50%] mobile:w-full">
                <p className="text-h2 font-[700] text-[#FFF] font-lora mobile:text-4xl mobile:p-3">
                    About <span className="text-secondary">Nishant Arora</span>
                </p>
                <hr className="w-[15%] mobile:w-[20%] mt-5 border-[3px] border-[#FFBB6B]" />
                <p className="mt-5 mobile:w-full mobile:text-justify text-[#FFF] font-poppins font-light w-[73%]">
                    Nishant Arora is a visionary entrepreneur and educator who has made
                    significant strides in the realm of trading education and personal
                    development. As the driving force behind Train For Success (TFS) and
                    The Inner Circle (TIC), he has revolutionized the way individuals
                    approach trading and self-improvement.
                </p>
                {/* 2 */}
                <hr className="w-[15%] mobile:hidden mt-5 border-[2px] border-[#FFBB6B]" />
                <div className="flex flex-wrap mobile:w-full mobile:flex-col items-center gap-5 mt-10">
                    <Link
                        to={"/aboutus"}
                        className="py-3 mobile:w-full text-[#FFF] flex justify-center text-body items-center font-[600] text-[20px] font-poppins px-5 border-[3px] border-[#FFF] rounded-[35px]"
                    >
                        {"Learn More >>"}
                    </Link>
                    <Link
                        to={"/contactus"}
                        className="py-4 mobile:w-full mobile:mb-28 text-primary flex justify-center text-body items-center font-[600] text-[20px] font-poppins getInTouch px-5"
                    >
                        Get In Touch
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default About;
