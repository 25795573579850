/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import 'react-loading-skeleton/dist/skeleton.css';
import { useDispatch, useSelector } from 'react-redux';
import { getProfileInfo } from '../store/actions/auth.action';
import StepIndicator from "./StepIndicator"
import ProfileDetails from '../components/Profile/ProfileDetails';
import ChangePwd from '../components/Profile/ChangePwd';
import BatchChange from '../components/Profile/BatchChange';
const Profile = () => {

  // ---------------------------------------------- All Variables ----------------------------------------------

  const dispatch = useDispatch();
  const [openTab, setOpenTab] = useState(1);
  const { profileData } = useSelector((state) => state?.auth);

  // ------------------------------------------------ useEffect to get profile info -----------------------------------

  useEffect(() => {
    dispatch(getProfileInfo());
  }, [dispatch, openTab]);


  return (
    <>
      <div className="bg-[#fffbf1] bg-no-repeat h-full -mt-5">
        <div className='flex flex-col justify-center items-center h-full w-full mobile:px-3 px-48'>
          <div className="w-full mt-36 ">
            <div className="text-sm font-medium text-center text-gray-500 border-b-[3px] border-tertiary dark:text-gray-400 dark:border-gray-700">
              <ul className="flex flex-wrap mobile:flex-nowrap  mobile:w-full mobile:overflow-scroll gap-10 -mb-px" role="tablist">
                {profileData?.kycVerified === false ? <li className="me-2">
                  <a
                    onClick={(e) => {
                      e.preventDefault();
                      setOpenTab(1);
                    }}
                    href="#"
                    className={`inline-block p-4 rounded-t-lg text-2xl text-primary ${openTab === 4 ? 'border-b-[3px] border-primary font-bold text-primary' : 'font-normal'}`}
                  >
                    kyc form
                  </a>
                </li> :
                  <>
                    <li className="me-2 mobile:me-0 mobile:w-full">
                      <a
                        onClick={(e) => {
                          e.preventDefault();
                          setOpenTab(1);
                        }}
                        href="#"
                        className={`inline-block p-4 text-2xl mobile:w-full text-primary rounded-t-lg ${openTab === 1 ? 'border-b-[3px] border-primary font-bold' : 'font-normal'}`}
                      >
                        Profile Details
                      </a>
                    </li>
                    <li className="me-2">
                      <a
                        onClick={(e) => {
                          e.preventDefault();
                          setOpenTab(2);
                        }}
                        href="#"
                        className={`inline-block p-4 rounded-t-lg text-2xl text-primary ${openTab === 2 ? 'border-b-[3px] border-primary font-bold text-primary' : 'font-normal'}`}
                      >
                        Change Password
                      </a>
                    </li>
                    <li className="me-2">
                      <a
                        onClick={(e) => {
                          e.preventDefault();
                          setOpenTab(3);
                        }}
                        href="#"
                        className={`inline-block p-4 rounded-t-lg text-2xl text-primary ${openTab === 3 ? 'border-b-[3px] border-primary font-bold text-primary' : 'font-normal'}`}
                      >
                        Change Batch
                      </a>
                    </li>
                  </>}
              </ul>
            </div>

            <div className="relative flex flex-col min-w-0 mt-10 mobile:mt-10 w-full mb-6">
              <div className="mobile:overflow-auto w-full flex justify-center">
                <div className="tab-content tab-space w-[100%] flex justify-center h-full flex-col">
                  {profileData && (profileData?.kycVerified === false ?
                    <div className={openTab === 1 ? 'block' : 'hidden'}>
                      <StepIndicator profileData={profileData} />
                    </div> : <>
                      <div className={openTab === 1 ? 'block' : 'hidden'}>
                        <ProfileDetails openTab={openTab} />
                      </div>
                      <div className={openTab === 2 ? 'block' : 'hidden'}>
                        <ChangePwd />
                      </div>
                      <div className={openTab === 3 ? 'block' : 'hidden'}>
                        <BatchChange profileData={profileData} openTab={openTab} />
                      </div>
                    </>)  
                  }

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Profile;
