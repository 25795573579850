import React, { useEffect, useState } from 'react';
import blogImg from "../../Images/blog.png";
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { blogList } from '../../store/actions/website.action';

const Blogs = () => {
    const { allBlogs, loader } = useSelector((state) => state?.web);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        const fetchBlogs = async () => {
            dispatch(blogList());
        };
        fetchBlogs();
    }, [dispatch]);

    const navigateToDetailPage = (index) => {
        const blogId = index;
        const url = `/blogs/${blogId}`;
        navigate(url);
    };

    const truncateTitle = (title) => {
        return title?.length > 20 ? `${title.substring(0, 28)}...` : title;
    };

    return (
        <div className='bg-tertiary'>
            <div className='max-w-screen-xl  mt-20  h-full flex flex-wrap items-center justify-between mx-auto p-6'>
                <div className='flex mobile:flex-col  w-full gap-14 mt-12'>
                    <div className='w-[83%] mobile:w-full'>
                        <p className='text-h6 font-[700] text-primary'>Our Blogs</p>
                        <p className='text-h2 mobile:text-h4 font-[700] text-primary font-lora mt-3'>Empower Your Trading Journey:<br />
                            <span className='text-secondary'>Discover Valuable Resources in Our Blog</span></p>
                    </div>
                    <div className='w-[17%] mobile:hidden flex items-end'>
                        <Link to={"/blogs"}>
                            <p className='border-primary border-2 cursor-pointer rounded-[35px] px-[32px] py-[14px] text-primary text-body font-[600] font-poppins'>View All Blogs</p>
                        </Link>
                    </div>
                </div>
                <div className='flex gap-12 w-full mt-20 justify-center items-center '>
                    <div className='w-[60%] min-h-[250px] flex flex-col gap-8 mobile:hidden'>

                        {allBlogs?.blogList?.[0] && <div className='bg-white flex rounded-[60px]'>
                            <div className='w-[35%]'>
                                {allBlogs?.blogList?.[0]?.attachments?.length > 0 ? (
                                    <img src={allBlogs?.blogList?.[0]?.attachments[0]} alt={truncateTitle(allBlogs?.blogList?.[0]?.title)} className="rounded-l-[60px] h-full object-cover" />
                                ) : (
                                    <img src={blogImg} alt="Default" className="rounded-l-[60px] h-full object-cover" />
                                )}
                            </div>
                            <div className='w-[55%]'>
                                <div className='px-10 py-7  flex flex-col justify-between items-baseline'>
                                    <p className='text-[14px] mt-2 font-[400] text-primary font-poppins '>{allBlogs?.blogList?.[0]?.updatedDate}</p>
                                    <p className='text-[18px] mt-2 font-[600] text-primary font-poppins tracking-wide'>{truncateTitle(allBlogs?.blogList?.[0]?.title)}</p>
                                    <p className="boxShadow mt-5 bg-primary cursor-pointer rounded-[35px] w-[65%] py-3 flex justify-center text-[20px] font-[600] font-poppins h-[35%] items-center text-white  mobile:py-1 mobile:w-full"
                                        onClick={() => navigateToDetailPage(allBlogs?.blogList?.[0]?.blogId)}>Read Now</p>
                                </div>
                            </div>
                        </div>}
                        <div className='w-full flex gap-8 '>
                            {allBlogs?.blogList?.[1] && <div className='w-[50%]'>
                                <div className='w-full bg-white flex min-h-[350px] flex-col rounded-[60px]'>
                                    {allBlogs?.blogList?.[1]?.attachments?.length > 0 ? (
                                        <img src={allBlogs?.blogList?.[1]?.attachments[0]} alt={truncateTitle(allBlogs?.blogList?.[1]?.title)} className="rounded-t-[60px] h-[174px] object-cover" />
                                    ) : (
                                        <img src={blogImg} alt="Default" className="rounded-t-[60px] h-[174px] object-cover" />
                                    )}
                                    <div className='px-5 py-3  flex flex-col justify-between items-baseline'>
                                        <p className='text-[14px] mt-2 font-[400] text-primary font-poppins '>{allBlogs?.blogList?.[1]?.updatedDate}</p>
                                        <p className='text-[18px] mt-2 font-[600] text-primary font-poppins tracking-wide'>{truncateTitle(allBlogs?.blogList?.[1]?.title)}</p>
                                        <div className='flex justify-center w-full '>
                                            <p className='boxShadow mt-3 mb-3 bg-primary rounded-[35px] w-[65%] py-3 flex justify-center text-[20px] font-[600] font-poppins h-[35%] items-center text-white  mobile:py-1 mobile:w-full cursor-pointer' onClick={() => navigateToDetailPage(allBlogs?.blogList?.[1]?.blogId)}>Read Now</p>
                                        </div>
                                    </div>
                                </div>
                            </div>}
                            {allBlogs?.blogList?.[2] && <div className='w-[50%]'>
                                <div className='w-full min-h-[350px] bg-white flex flex-col rounded-[60px]'>
                                    {allBlogs?.blogList?.[2]?.attachments?.length > 0 ? (
                                        <img src={allBlogs?.blogList?.[2]?.attachments[0]} alt={truncateTitle(allBlogs?.blogList?.[2]?.title)} className="rounded-t-[60px] h-[174px] object-cover" />
                                    ) : (
                                        <img src={blogImg} alt="Default" className="rounded-t-[60px] h-[174px] object-cover" />
                                    )}
                                    <div className='px-5 py-3  flex flex-col justify-between items-baseline'>
                                        <p className='text-[14px] mt-2 font-[400] text-primary font-poppins'>{allBlogs?.blogList?.[2]?.updatedDate}</p>
                                        <p className='text-[18px] mt-2 font-[600] text-primary font-poppins tracking-wide'>{truncateTitle(allBlogs?.blogList?.[2]?.title)}</p>
                                        <div className='flex justify-center w-full '>
                                            <p className='boxShadow mt-3 mb-3 bg-primary rounded-[35px] w-[65%] mobile:py-1 mobile:w-full py-3 flex justify-center text-[20px] font-[600] font-poppins h-[35%] items-center text-white cursor-pointer' onClick={() => navigateToDetailPage(allBlogs?.blogList?.[2]?.blogId)}>Read Now</p>
                                        </div>
                                    </div>
                                </div>
                            </div>}
                        </div>
                    </div>
                    {allBlogs?.blogList?.[3] &&
                        <div className='w-[40%] mobile:w-full'>
                            <div className='flex w-full h-[160px]'>
                                <div className='w-[25%] mobile:w-[30%] '>
                                    {allBlogs?.blogList?.[3]?.attachments?.length > 0 ? (
                                        <img src={allBlogs?.blogList?.[3]?.attachments[0]} alt={truncateTitle(allBlogs?.blogList?.[3]?.title)} className="rounded-[15px] h-full object-cover" />
                                    ) : (
                                        <img src={blogImg} alt="Default" className="rounded-[15px] h-full object-cover" />
                                    )}
                                </div>
                                <div className='w-[75%] mobile:w-[70%]'>
                                    <div className='px-5 py-3  flex flex-col justify-between items-baseline'>
                                        <p className='text-[14px] font-[400] text-primary font-poppins '>{allBlogs?.blogList?.[3]?.updatedDate}</p>
                                        <p className='text-[18px] mt-2 font-[600] text-primary font-poppins '>{truncateTitle(allBlogs?.blogList?.[3]?.title)}</p>
                                        <p className='boxShadow mt-3 bg-primary rounded-[35px] w-[45%] py-2 flex justify-center text-[20px] font-[600] cursor-pointer font-poppins h-[35%] items-center text-white mobile:py-1 mobile:w-[70%]' onClick={() => navigateToDetailPage(allBlogs?.blogList?.[3]?.blogId)}>Read Now</p>
                                    </div>
                                </div>
                            </div>
                            <hr className='border-primary border-[1.5px] mt-6 mb-6' />
                            <div className='flex w-full h-[160px]'>
                                <div className='w-[25%] mobile:w-[30%]'>
                                    {allBlogs?.blogList?.[4]?.attachments?.length > 0 ? (
                                        <img src={allBlogs?.blogList?.[4]?.attachments[0]} alt={truncateTitle(allBlogs?.blogList?.[4]?.title)} className="rounded-[15px] h-full object-cover" />
                                    ) : (
                                        <img src={blogImg} alt="Default" className="rounded-[15px] h-full object-cover" />
                                    )}
                                </div>
                                <div className='w-[75%] mobile:w-[70%]'>
                                    <div className='px-5 py-3  flex flex-col justify-between items-baseline'>
                                        <p className='text-[14px] font-[400] text-primary font-poppins '>{allBlogs?.blogList?.[4]?.updatedDate}</p>
                                        <p className='text-[18px] mt-2 font-[600] text-primary font-poppins '>{truncateTitle(allBlogs?.blogList?.[4]?.title)}</p>
                                        <p className='boxShadow mt-3 bg-primary rounded-[35px] w-[45%] py-2 flex justify-center text-[20px] font-[600] font-poppins cursor-pointer h-[35%] items-center text-white mobile:py-1 mobile:w-[70%]' onClick={() => navigateToDetailPage(allBlogs?.blogList?.[4]?.blogId)}>Read Now</p>
                                    </div>
                                </div>
                            </div>
                            <hr className='border-primary border-[1.5px] mt-6 mb-6' />
                            <div className='flex w-full h-[160px]'>
                                <div className='w-[25%] mobile:w-[30%]'>
                                    {allBlogs?.blogList?.[5]?.attachments?.length > 0 ? (
                                        <img src={allBlogs?.blogList?.[5]?.attachments[0]} alt={truncateTitle(allBlogs?.blogList?.[5]?.title)} className="rounded-[15px] h-full object-cover" />
                                    ) : (
                                        <img src={blogImg} alt="Default" className="rounded-[15px] h-full object-cover" />
                                    )}
                                </div>
                                <div className='w-[75%] mobile:w-[70%]'>
                                    <div className='px-5 py-3   flex flex-col justify-between items-baseline'>
                                        <p className='text-[14px] font-[400] text-primary font-poppins '>{allBlogs?.blogList?.[5]?.updatedDate}</p>
                                        <p className='text-[18px] mt-2 font-[600] text-primary font-poppins '>{truncateTitle(allBlogs?.blogList?.[5]?.title)}</p>
                                        <p className='boxShadow mt-3 bg-primary rounded-[35px] w-[45%] py-2 flex justify-center text-[20px] cursor-pointer font-[600] font-poppins h-[35%] items-center text-white mobile:py-1 mobile:w-[70%]' onClick={() => navigateToDetailPage(allBlogs?.blogList?.[5]?.blogId)}>Read Now</p>
                                    </div>
                                </div>
                            </div>
                        </div>}
                </div>
            </div>
        </div >
    );
};

export default Blogs;
