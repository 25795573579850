import React from 'react';
import previous from "../../Images/prev.svg";
import next from "../../Images/next.svg";

const Pagination = ({ currentPage, totalPages, paginate }) => {
    const pageNumbers = [];
    const maxPageNumbersToShow = 5;

    if (totalPages <= maxPageNumbersToShow) {
        for (let i = 1; i <= totalPages; i++) {
            pageNumbers.push(i);
        }
    } else {
        if (currentPage <= 3) {
            pageNumbers.push(1, 2, 3, 4, '...', totalPages);
        } else if (currentPage > totalPages - 3) {
            pageNumbers.push(1, '...', totalPages - 3, totalPages - 2, totalPages - 1, totalPages);
        } else {
            pageNumbers.push(1, '...', currentPage - 1, currentPage, currentPage + 1, '...', totalPages);
        }
    }

    const handleClick = (pageNumber) => {
        paginate(pageNumber);
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    return (
        <nav aria-label="Page navigation example">
            <ul className="flex items-center justify-center mt-20 -space-x-px h-8 text-sm">
                <li>
                    <button
                        onClick={() => handleClick(currentPage - 1)}
                        disabled={currentPage === 1}
                        className="flex items-center justify-center px-5 h-8 leading-tight text-gray-500 rounded-s-l"
                    >
                        <span className="sr-only">Previous</span>
                        <img src={previous} alt="Previous" />
                    </button>
                </li>
                {pageNumbers.map((number, index) => (
                    <li key={index}>
                        {number === '...' ? (
                            <span className="flex items-center justify-center h-8 w-8 text-gray-500">...</span>
                        ) : (
                            <button
                                onClick={() => handleClick(number)}
                                className={`flex items-center justify-center h-8 w-8 leading-tight ${currentPage === number ? 'text-primary font-bold bg-[#2142841c] rounded-full px-4 py-3' : 'text-gray-500 px-5'}`}
                            >
                                {number}
                            </button>
                        )}
                    </li>
                ))}
                <li>
                    <button
                        onClick={() => handleClick(currentPage + 1)}
                        disabled={currentPage === totalPages}
                        className="flex items-center justify-center px-5 h-8 leading-tight text-gray-500 rounded-e-l"
                    >
                        <span className="sr-only">Next</span>
                        <img src={next} alt="Next" />
                    </button>
                </li>
            </ul>
        </nav>
    );
};

export default Pagination;
