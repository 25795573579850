import React from 'react'

const Portal = () => {
    return (
        <div>
            Portal
        </div>
    )
}

export default Portal
