import React, { useEffect } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import toast, { Toaster } from "react-hot-toast";
import Button from "./Button";

// Define the validation schema with Yup
const validationSchema = Yup.object({
  agreement1: Yup.boolean().oneOf([true], "You must accept this agreement."),
  agreement2: Yup.boolean().oneOf([true], "You must accept this agreement."),
  agreement3: Yup.boolean().oneOf([true], "You must accept this agreement."),
  agreement4: Yup.boolean().oneOf([true], "You must accept this agreement."),
  agreement5: Yup.boolean().oneOf([true], "You must accept this agreement."),
});

const KycDeclaration = ({ setCurrentStep, onSubmit, onBack }) => {
  // Retrieve initial values from localStorage
  const getInitialValues = () => {
    const storedValues = JSON.parse(localStorage.getItem("checkbox")) || {};
    return {
      agreement1: storedValues.agreement1 || false,
      agreement2: storedValues.agreement2 || false,
      agreement3: storedValues.agreement3 || false,
      agreement4: storedValues.agreement4 || false,
      agreement5: storedValues.agreement5 || false,
    };
  };

  // Function to handle checkbox changes and update localStorage
  const handleChange = (e, setFieldValue) => {
    const { name, checked } = e.target;
    setFieldValue(name, checked);

    // Update localStorage with the latest form values
    const existingValues = JSON.parse(localStorage.getItem("checkbox")) || {};
    localStorage.setItem(
      "checkbox",
      JSON.stringify({
        ...existingValues,
        [name]: checked,
      })
    );
  };

  const handleSubmit = (values, { setSubmitting }) => {
    // Proceed to the next step only if validation is successful
    if (Object.values(values).some(value => !value)) {
      toast.error("You must accept all terms and conditions.");
    } else {
      onSubmit(values);
      setCurrentStep(4); // Proceed to the next step
    }
    setSubmitting(false);
  };

  return (
    <div className="w-full relative">
      <div className="flex justify-center items-center">
        <div className="p-8 rounded-[40px] mt-10 mb-10 w-full max-w-4xl laptop:max-w-6xl mobile:rounded-none mobile:mt-0 mobile:mb-0 mobile:p-0">
          <h2 className="text-2xl text-center mt-7 text-[#214284] font-lora font-semibold laptop:hidden mobile:p-6 mobile:bg-[#fffaed]">
            Please Fill In This KYC Form To Help Us Know You Better!
          </h2>
          <h2 className="text-2xl text-center mb-3 mt-7 text-[#214284] font-lora font-semibold mobile:hidden">
            Please Fill In This KYC Form To Help Us Know You Better!
          </h2>

          <hr className="border-t-2 opacity-16 mb-10 mobile:hidden" />

          <div className="flex items-center mt-6 space-x-2 mobile:p-5">
            <h3 className="text-2xl font-lora font-bold text-[#214284]">
              KYC Declaration
            </h3>
            <hr className="flex-grow border-t-2 opacity-16 mobile:hidden" />
          </div>
          <div className="flex items-center space-x-2">
            <p className="mobile:px-7 mt-8 mb-6 font-poppins font-light text-[#214284]">
              Please read and acknowledge the below mentioned points:
            </p>
          </div>

          <Formik
            initialValues={getInitialValues()}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ values, setFieldValue }) => (
              <Form className="space-y-6 mobile:px-7">
                {["agreement1", "agreement2", "agreement3", "agreement4", "agreement5"].map((agreement, index) => (
                  <div key={index} className="flex items-center mb-4">
                    <Field
                      id={agreement}
                      name={agreement}
                      type="checkbox"
                      className="w-4 h-4 text-secondary bg-gray-100 size-3 mr-6 border-primary rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700"
                      checked={values[agreement]}
                      onChange={(e) => handleChange(e, setFieldValue)}
                    />
                    <label
                      htmlFor={agreement}
                      className="ms-2 text-sm font-light font-poppins text-primary dark:text-gray-300"
                    >
                      {agreement === "agreement1" && "I hereby agree and undertake that the aforesaid information provided by me is true, complete and accurate in all respects and not misleading. In case it is found to be untrue or misleading, TFS reserves the right to reject the admission and make the refund."}
                      {agreement === "agreement2" && "I undertake to immediately update the information provided herein in the event there is any change in the said information and inform TFS in writing within 7 (seven) days of such change."}
                      {agreement === "agreement3" && "I hereby permit TFS to use the information provided in this KYC Form for the provision of services by TFS."}
                      {agreement === "agreement4" && "I hereby declare that I have read and understood the TFS Agreement as provided in link here. This KYC Form forms an integral part of the TFS Agreement."}
                      {agreement === "agreement5" && "By submitting this KYC Form, I hereby agree and declare that I have read TFS Agreement and TFS Agreement constitutes legally, valid and binding agreement on me and I hereby give my free consent to be bound by the terms and conditions of TFS Agreement."}
                    </label>
                  </div>
                ))}
                <hr className="flex-grow border-t-2 mt-5 opacity-16" />
                <div className="flex flex-col space-y-3 md:flex-row md:space-y-0 md:space-x-3 mobile:justify-center mobile:items-center">
                  <div className="mobile:justify-center items-center w-full md:w-1/2 md:justify-start text-[#214284]">
                    <div onClick={onBack} className="w-full md:w-auto">
                      <Button label="Back" type="button" variant="outline" />
                    </div>
                  </div>
                  <div className="mobile:justify-center items-center w-full md:w-1/2 md:justify-end">
                    <div className="w-full md:w-auto">
                      <Button label="Next" type="submit" variant="solid" />
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
      <Toaster />
    </div>
  );
};

export default KycDeclaration;
