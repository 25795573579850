import { ErrorMessage, Field, Form, Formik } from 'formik';
import React from 'react'
import { changepwd } from '../../store/actions/auth.action';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';

const ChangePwd = () => {
  const dispatch = useDispatch();
  const passwordValidationSchema = Yup.object().shape({
    currentpwd: Yup.string().required('Current Password is required'),
    newpwd: Yup.string().required('New Password is required'),
    confirm: Yup.string()
      .oneOf([Yup.ref('newpwd'), null], 'Passwords must match')
      .required('Confirm Password is required'),
  });
  return (
    <>
      <Formik
        initialValues={{
          currentpwd: '',
          newpwd: '',
          confirm: ''
        }}
        validationSchema={passwordValidationSchema}
        onSubmit={(values) => {
          const payload = {
            currentPassword: values.currentpwd,
            newPassword: values.newpwd,
            action: "SUBMIT"
          }
          dispatch(changepwd(payload));
          console.log('Password form values:', values);
        }}
      >
        {({ isValid, dirty }) => (
          <Form className="w-full bg-white rounded-[40px] mobile:rounded-none h-full mobile:p-3">
            <div className="p-10 mobile:p-3">
              <div className="grid grid-cols-3 mobile:grid-cols-1 mobile:gap-3 gap-7 mt-5">
                <div className="mb-5">
                  <label htmlFor="currentpwd" className="text-base font-normal font-poppins block mb-2 text-primary">
                    Current Password <span className='text-secondary'>*</span>
                  </label>
                  <Field
                    type="password"
                    id="currentpwd"
                    name="currentpwd"
                    className="border border-primary text-primary text-base font-semibold font-poppins rounded-[39px] focus:ring-0 focus:border-primary block w-full p-2.5 placeholder:text-primary"
                    placeholder="Current Password"
                  />
                  <ErrorMessage name="currentpwd" component="div" className="text-red-500" />
                </div>
                <div className="mb-5">
                  <label htmlFor="newpwd" className="text-base font-normal font-poppins block mb-2 text-primary">
                    New Password <span className='text-secondary'>*</span>
                  </label>
                  <Field
                    type="password"
                    id="newpwd"
                    name="newpwd"
                    className="border border-primary text-primary text-base font-semibold font-poppins rounded-[39px] focus:ring-0 focus:border-primary block w-full p-2.5 placeholder:text-primary"
                    placeholder="New Password"
                  />
                  <ErrorMessage name="newpwd" component="div" className="text-red-500" />
                </div>
                <div className="mb-5">
                  <label htmlFor="confirm" className="text-base font-normal font-poppins block mb-2 text-primary">
                    Confirm Password <span className='text-secondary'>*</span>
                  </label>
                  <Field
                    type="password"
                    id="confirm"
                    name="confirm"
                    className="border border-primary text-primary text-base font-semibold font-poppins rounded-[39px] focus:ring-0 focus:border-primary block w-full p-2.5 placeholder:text-primary"
                    placeholder="Confirm Password"
                  />
                  <ErrorMessage name="confirm" component="div" className="text-red-500" />
                </div>
              </div>
              <hr className="border-t-2 color-secondary opacity-16 laptop:hidden " />
              <div className="flex justify-end mt-5">
                <button
                  type="submit"
                  className={`text-white bg-primary rounded-[39px] mobile:w-full text-lg px-10 py-2 ${!isValid || !dirty ? 'opacity-50 cursor-not-allowed' : 'opacity-100'}`}
                  disabled={!isValid || !dirty}
                >
                  Change Password
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </>
  )
}

export default ChangePwd
