import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  HashRouter,
} from "react-router-dom";
import "./App.css";
import "./index.css";
import "./Images/bg.svg";
import Header from "./components/Header";
import Homepage from "./pages/Homepage";
import AboutUs from "./pages/AboutUs";
import Resources from "./pages/Resources";
import ContactUs from "./pages/ContactUs";
import Blogs from "./pages/Blogs";
import Pricing from "./pages/Pricing";
import Portal from "./pages/Portal";
import Footer from "./components/Footer";
import BlogDetail from "./components/Blogs/BlogDetail";
import InsieAdmin from "./pages/InsieAdmin";
import EventDetails from "./components/EventDetails";
import Profile from "./pages/Profile";
import Login from "./pages/Login";
import LoginHeader from "./components/LoginHeader";
import { Toaster } from "react-hot-toast";
import GetStarted from "./pages/GetStarted";
import KYCFormComponent from "./pages/KycConfirmation";
import Confirmation from "./pages/Confirmation";
import GetPayment from "./pages/GetPayment";
import KycVerification from "./pages/KycVerification";
import KycDeclaration from "./pages/KycDeclaration";
import KycComplete from "./pages/KycComplete";
import KycCompleteToDashboard from "./pages/KycCompleteToDashbord";
import AdminHeader from "./components/AdminHeader";
import AdminFooter from "./components/AdminFooter";

function App() {
  return (
    <HashRouter>
      <div className="App mobile:overflow-x-hidden">
        <Toaster />
        <Routes>
          <Route path="/*" element={<Navigate to={"/home"} />} />
          <Route
            path="/home"
            element={
              <>
                <Header />
                <Homepage />
                <Footer />
              </>
            }
          />
          <Route
            path="/aboutus"
            element={
              <>
                <Header />
                <AboutUs />
                <Footer />
              </>
            }
          />
          <Route
            path="/resources"
            element={
              <>
                <Header />
                <Resources />
                <Footer />
              </>
            }
          />
          <Route
            path="/pricing"
            element={
              <>
                <Header />
                <Pricing />
                <Footer />
              </>
            }
          />
          <Route
            path="/blogs/:id"
            element={
              <>
                <Header />
                <BlogDetail />
                <Footer />
              </>
            }
          />
          <Route
            path="/blogs"
            element={
              <>
                <Header />
                <Blogs />
                <Footer />
              </>
            }
          />
          <Route
            path="/contactus"
            element={
              <>
                <Header />
                <ContactUs />
                <Footer />
              </>
            }
          />
          <Route
            path="/portal"
            element={
              <>
                <Header />
                <Portal />
                <Footer />
              </>
            }
          />

          <Route
            path="/insiescreen"
            element={
              <>
                <LoginHeader />
                <InsieAdmin />
                <Footer />
              </>
            }
          />
          <Route
            path="/events/:id"
            element={
              <>
                <LoginHeader />
                <EventDetails />
                <Footer />
              </>
            }
          />
          <Route
            path="/scheduled/:id"
            element={
              <>
                <LoginHeader />
                <BlogDetail />
                <Footer />
              </>
            }
          />
          <Route
            path="/profile"
            element={
              <>
                <LoginHeader />
                <Profile />
                <Footer />
              </>
            }
          />
          <Route
            path="/login"
            element={
              <>
                <Login />
              </>
            }
          />
          <Route
            path="/KycComplete"
            element={
              <>
                <AdminHeader />
                <KycComplete />
                <AdminFooter />
              </>
            }
          />
          <Route
            path="/KycDeclaration"
            element={
              <>
                <AdminHeader />
                <KycDeclaration />
                <AdminFooter />
              </>
            }
          />
          <Route
            path="/kycform"
            element={
              <>
                <AdminHeader />
                <KYCFormComponent />
                <AdminFooter />
              </>
            }
          />
          <Route
            path="/kycVerification"
            element={
              <>
                <AdminHeader />
                <KycVerification />
                <AdminFooter />
              </>
            }
          />

          <Route
            path="/getstarted"
            element={
              <>
                <AdminHeader />
                <GetStarted />
                <AdminFooter />
              </>
            }
          />
          <Route
            path="/ktdash"
            element={
              <>
                <AdminHeader />
                <KycCompleteToDashboard />
                <AdminFooter />
              </>
            }
          />
          <Route
            path="/kyc-payment"
            element={
              <>
                <AdminHeader />
                <GetPayment />
                <AdminFooter />
              </>
            }
          />
          <Route
            path="/confirmation"
            element={
              <>
                <AdminHeader />
                <Confirmation />
                <AdminFooter />
              </>
            }
          />
        </Routes>
      </div>
    </HashRouter>
  );
}

export default App;
