import React from 'react'
import { Link } from "react-router-dom";

const AdminFooter = () => {
  return (
    <div>
       <footer className="bg-white shadow-md absolute  w-full ">
        <div className="container mx-auto p-4 flex justify-between items-center">
          {/* Left Section */}
          <div className="text-[#214284]">
            <p>
              <Link to="/" className="underline">
                Terms and Conditions
              </Link>{" "}
              |{" "}
              <Link to="/" className="underline">
                Privacy Policy
              </Link>{" "}
              |{" "}
              <Link to="/" className="underline">
                Contact Us
              </Link>
            </p>

          </div>

          {/* Right Section */}
          <div className="text-[#214284]">
            {/* Content for the right section goes here */}
            <p className="mt-2">&copy; 2021 All Rights Reserved by TFS</p>
          </div>
        </div>
      </footer>
    </div>
  )
}

export default AdminFooter
